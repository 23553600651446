import { Form, Input, Modal, Select } from "antd";
import "./RegisterThemse.scss";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {closeModal} from "../../../redux/Slice/modalSlice";
import RegisterApi from "../../../Api/Register";
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import imgSuccess from "../../../asset/images/success.png";
import imgError from "../../../asset/images/error.png";
import Loading from "../Loading/Loading";
import { CloseLoading, openLoading } from "../../../redux/Slice/loadingSlice";
import { useParams } from "react-router";
import { getListThemseBus } from "../../../config/helper";
const options = [
  {
    label: ">10 xe",
    value: "1",
  },
  {
    label: "10-20 xe",
    value: "2",
  },
  {
    label: "20-50 xe",
    value: "3",
  },
  {
    label: ">50 xe",
    value: "4",
  },
];
const { Option } = Select;
const RegisterThemse = () => {
  const { id } = useParams();
  const idThem = id;
  const listThemseBus = useSelector((state)=> state?.themseBus?.data?.data);
  const themseDetail = getListThemseBus(listThemseBus,idThem) 
  const themseDetail1 = sessionStorage.getItem("idThemse");
  const themseBusId = JSON.parse(themseDetail1);
  const [form] = Form.useForm();
  const [isModalOpenSuccse, setIsModalOpenSuccse] = useState(false);
  const [isModalOpenError, setIsModalOpenError] = useState(false);
  const [successe, setSuccess] = useState("");
  const [error, setError] = useState([]);
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.modal.isOpen)
  const handleClose = () => {

    dispatch(closeModal());
    form.resetFields();
  };
  const hiddenModal = () => {
    setIsModalOpenSuccse(false)
    setIsModalOpenError(false);
  };
  const onFinishFailed = (errorInfo) => {
  
  };
  const onFinish = async (values) => {
    try {
      const selectedOption = options?.find(option => option.value === values.contact_company_size);
      const selectedLabel = selectedOption ? selectedOption.label : '';
 
      const valueData = {
        ...values,
        site_id: 1,
        contact_company_size: selectedLabel,
        themse_id : themseBusId?.id || themseDetail?.themseId,
      };
      dispatch(openLoading());
      const response = await RegisterApi.Create(valueData);
     
        if (response.status === 200) {
          dispatch(CloseLoading());
            setIsModalOpenSuccse(true);
            setSuccess(response.data.message);
            form.resetFields();
        
          return response;
        }
        if (response.status === 429) {
          dispatch(CloseLoading());
          setIsModalOpenSuccse(true);
          setSuccess(response.data.message);
          
        } else {
          dispatch(CloseLoading());
          setIsModalOpenError(true);
          setError(response.data.message);  
        }
    } catch (error) {  
       dispatch(CloseLoading()); 
        setIsModalOpenError(true);
        setError("Lỗi đăng kí xin vui lòng thử lại sau!");  
    }
  }
  useEffect(() => {
    if(themseBusId?.name ){
     form.setFieldsValue({ themes_id: themseBusId?.name });
    } else{
      form.setFieldsValue({ themes_id: themseDetail?.themseName });
    }
  },)
  return (
    <>
      <div className="ModalRegisterThemse">
        <Modal
          width={720}
          title={
            <div className="headerModalThemse ">
              <p>Đăng ký mẫu website cho nhà xe </p>
              <div className="buton-close-modal-them">
                <FontAwesomeIcon
                 onClick={handleClose}
                 icon={faXmark}
                />
              </div>
            </div>
          }
          open={isOpen}
          style={{ with: "720px !importaion" }}
          className="containerModalThemse"
        >
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="formRegisterThemse"
          >
            <div className="inputRegister">
              <div className="inputLeft inputLeftMobile">
                
                <p className="titleInputThem titleInputMobile">
                  Tên công ty(nhà xe)<sup className="subText">*</sup>
                </p>
                <Form.Item
                  name="contact_company_name"
                  rules={[
                    {
                      required: true,
                      message: "vui lòng nhập tên công ty(nhà xe)!",
                    },
                  ]}
                >
                  <Input placeholder="Nhập tên công ty(nhà xe)" />
                </Form.Item>
              </div>
              <div className="inputRight">
                <p className="titleInputThem titleInputMobile">
                  Người liên hệ <sup className="subText">*</sup>
                </p>
                <Form.Item
                  name="contact_name"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập người liên hệ!",
                    },
                  ]}
                >
                  <Input placeholder="Nhập tên người liên hệ" />
                </Form.Item>
              </div>
            </div>
            <div>
              <p className="titleInputThem titleInputMobile">
                Địa chỉ <sup className="subText">*</sup>
              </p>
              <Form.Item
                name="contact_address"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập địa chỉ!",
                  },
                ]}
              >
                <Input placeholder="Nhập địa chỉ" />
              </Form.Item>
            </div>
            <div className="inputRegister">
              <div className="inputLeft inputLeftMobile">
                <p className="titleInputThem titleInputMobile">
                  Số điện thoại <sup className="subText">*</sup>{" "}
                </p>
                <Form.Item
                  name="contact_phone"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập số điện thoại!",
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Vui lòng chỉ nhập số !",
                    },
                  ]}
                >
                  <Input placeholder="Nhập số điện thoại" />
                </Form.Item>
              </div>
              <div className="inputRight">
                <p className="titleInputThem titleInputMobile">
                  Quy mô <sup className="subText">*</sup>
                </p>
                <Form.Item
                  name="contact_company_size"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập quy mô!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Vui lòng chọn quy mô"
                    style={{ width: "100%" }}
                  >
                    {options.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div>
              <p className="titleInputThem titleInputMobile">Email</p>
              <Form.Item
                name="contact_email"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập email!",
                  },
                  {
                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Vui lòng nhập đúng email !",
                  },
                ]}
              >
                <Input placeholder="Nhập Email" />
              </Form.Item>
            </div>
            <div>
              <p className="titleInputThem titleInputMobile">Lời nhắn</p>
              <Form.Item name="contact_message">
                <TextArea row={4} placeholder="Nhập lời nhắn bạn muốn để lại" />
              </Form.Item>
            </div>
            <div>
              <p className="titleInputThem titleInputMobile">Mẫu Webstie đã chọn</p>
              <Form.Item
                name="themes_id"              
              >

                <Input />
           
              </Form.Item>
            </div>
            <button
              className="buttonRegister buttonRegisterMobiles"
              type="submit"
            >
              Đăng ký ngay
            </button>
          </Form>
        </Modal>
      </div>
        <div>
        <Modal open={isModalOpenSuccse} width={520} className="modalNotification modalNotificationMobile" >
        <img alt="" className="imageSuccses imageSuccsesMobile" src={imgSuccess} />
           <h3>Nộp hồ sơ thành công</h3>
          {imgSuccess && <p>{successe} </p>}
          <button onClick={hiddenModal} className="buttonConfirm buttonConfirmMobile">
            Đã Hiểu
          </button>
        </Modal>
        <Modal open={isModalOpenError} width={520} className="modalNotification modalNotificationMobile">
        <img alt="" className="imageSuccses imageSuccsesMobile" src={imgError} />
           <h3>Nộp hồ sơ thất bại</h3>
          {imgError && <p>{error} </p>}
          <button onClick={hiddenModal} className="buttonConfirm buttonConfirmMobile">
            Đã Hiểu
          </button>
        </Modal>
      </div>
      <Loading/>
    </>
  );
};
export default RegisterThemse;
