import { createSlice } from "@reduxjs/toolkit";
 const loadingSlices = createSlice({
    name:'loading',
    initialState:{
        isOpen: false,
    },
    reducers:{
        openLoading:(state)=>{
            state.isOpen = true;
        },
        CloseLoading:(state)=>{
            state.isOpen = false;
        }
    },
});
export const {openLoading, CloseLoading} = loadingSlices.actions;
export default loadingSlices.reducer;
