import AxiosClient from "./api";
const Site = process.env.REACT_APP_SITE_ID;
const GetAll = (config) => {
  return AxiosClient.get(`newshomepage/${Site}/lang/1`, config);
};
const GetNewHot = (id, config) => {
  return AxiosClient.get(`listhotnewsbycategory/${id}/lang/1`, config);
};
const GetAllPagingPostNew = (id, page) => {
  return AxiosClient.get(`listnewsfilterbycategory/${id}/lang/1/filter/*?page=${page}`);
};
const GetAllPaingNewsSearch = (id, filter,page) => {
  return AxiosClient.get(`listnewsfilterbycategory/${id}/lang/1/filter/${filter}?page=${page}`
  );
};
const GetNewPostDetail = (id, config) => {
  return AxiosClient.get(`/getnewsdetail/${id}`, config);
};
const GetNextNews = (id, page) => {
  return AxiosClient.get(`/getnextnews/${id}/site/${Site}/lang/1?page=${page}`);
};

const PostNewsApi = {
  GetAll,
  GetAllPaingNewsSearch,
  GetNewHot,
  GetNextNews,
  GetAllPagingPostNew,
  GetNewPostDetail,
};

export default PostNewsApi;
