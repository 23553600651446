import "./new.scss";
import GoTop from "../../Component/PageComponent/GoTop/GoTop";
import FormRegister from "../../Component/PageComponent/FormRegister/FormRegister";
import PostNewsApi from "../../Api/New";
import NewHot from "../../Component/PageComponent/NewHot/NewHot";
import { Pagination, Input,message, Result  } from "antd";
import ContactChild from "../../Component/PageComponent/Contact/Contact";
import { useEffect, useState, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { getCategory, getListMetaSeo } from "../../config/helper";
import { SmileOutlined } from '@ant-design/icons';
const News = ({ targetRef }) => {
  const navigate = useNavigate();
  const newPost = useRef(null);
  const { Search } = Input;
  const [listPageingPostNew, setListPagingPostNew] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRows, setTotalRow] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [page, setPage] = useState(0);
  const [listSearch, setListSearch] = useState('');
  const [hasResult, setHasResult] = useState(true);
  const location = useLocation();
  const listCategory = useSelector((state)=> state.menu.data);
  const id = getCategory(listCategory, location?.pathname.slice(1));

  const listMetaSeo = getListMetaSeo(listCategory,location?.pathname.slice(1))
  const handelSearch = () => {
    if (/^[a-zA-Z]+$/.test(listSearch)) {
      message.error('Không được nhập ký tự đặc biệt!');
      return;
  }
    fetchListSearchPagingPostNew(listSearch);   
  };
  const search =()=>{
    if (/^[a-zA-Z]+$/.test(listSearch)) {
      message.error('Không được nhập ký tự đặc biệt!');
      return;
  }
    fetchListSearchPagingPostNew(listSearch);  
  }
  const valueSearch = (value) => {
    setListSearch(value);
    if (value === "") {
      
      fetchListPagingPostNew(setPage(0));
    }
  };
  const fetchListPagingPostNew = useCallback( async (current) => {
 
    try {
      const response = await PostNewsApi.GetAllPagingPostNew(id, current);
    
      setListPagingPostNew(response.data.data);

      setTotalRow(response.data.total);
      setCurrentPage(response.data.current_page);
      setPageSize(response.data.last_page);
    } catch (err) {
      throw err;
    }
  },[id]);
  const fetchListSearchPagingPostNew = async (listSearch,current) => {
 
    try {
      const response = await PostNewsApi.GetAllPaingNewsSearch(id, listSearch, current);
      if (response.data.data.length == 0) {
        setHasResult(false);
        return;
      }
     
      setListPagingPostNew(response.data.data);
      setHasResult(true);
      setTotalRow(response.data.total);
      setCurrentPage(response.data.current_page);
      setPageSize(response.data.last_page);
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    fetchListPagingPostNew(page );
  }, [page]);
  const handleOnchangePage = (current) => {
    setPage(current);
    scrollTopNew();
  };
 
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const scrollTopNew = () => {
    if (newPost.current) {
      const yOffset = -300;
      const y =
        newPost.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
        window.scrollTo({
        top: y,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
    {listMetaSeo &&(

    <Helmet>  
    <title>{listMetaSeo?.category_meta_title}</title>
      <meta name="description" content={listMetaSeo?.category_meta_description}/>
      <meta name="keywords" content={listMetaSeo?.category_meta_keywords}/>
      <meta property="og:locale" content=""/>
      <meta property="og:type" content=""/>
      <meta property="og:description" content=""/>
      <meta property="og:url" content=""/>
      <meta property="og:site_name" content=""/>
      <meta property="og:img" content=""/>
    </Helmet>
    )}
      <NewHot />
      <div className="headerPostNew headerPostNewMobile">
        <div className="headerTitle headerTitleMobile">
          <h1>Tin mới</h1>
        </div>
        <div className="wapperSelect wapperSelectMobile">
          <Search
            className="onsearch"
            placeholder="Nhập để tìm kiếm"
            onChange={(e) => valueSearch(e.target.value)}
            onSearch={handelSearch}
            onPressEnter={search}
            style={{
              width: "100%",
            }}
          />
        </div>
      </div>
      { hasResult ? (
      <div
        className="wapperPostNew wapperPostNewMobile"
        ref={newPost}
      >
        {listPageingPostNew?.map((item) => {
          return (
            <div
              className="containerPostNew containerPostNewMobile"
              onClick={() => {
                navigate(`chi-tiet/${item.news_id}`);
                scrollToTop();
              }}
            >
              <div
                className="imageContainerPostNew imageContainerPostNewMobile"
              >
                <img alt="" src={item?.news_thumb_image_url} />
              </div>
              <div
                className="containerContentPostNew containerContentPostNewMobile"
              >
                <div
                  className="titleContentPostNew titleContentPostNewMobile"
                >
                  <h2>{item?.title}</h2>
                </div>
                <div
                  className="contentTextPostNew contentTextPostNewMobile"
                >
                  <p>{item?.description}</p>
                </div>
              </div>
            </div>
          );
        })}
        <div className="postNewPaing postNewPaingMobile">
          <Pagination
            defaultCurrent={1}
            total={totalRows}
            current={currentPage}
            pageSize={pageSize}
            onChange={handleOnchangePage}
          />
        </div>
      </div>):(
         <Result
         icon={<SmileOutlined />}
         title="Không tìm thấy kết quả !!!"
       />
      )}
      <ContactChild />
      <FormRegister targetRef={targetRef} />
      <GoTop />
    </>
  );
};
export default News;
