import { useEffect, useState } from "react";
import "./newPostDetail.scss";
import { useNavigate, useParams } from "react-router";
import PostNewsApi from "../../Api/New";
import GoTop from "../../Component/PageComponent/GoTop/GoTop";
import ContactChild from "../../Component/PageComponent/Contact/Contact";
import {
  faXTwitter,
  faInstagram,
  faSquareFacebook,
} from "@fortawesome/free-brands-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BannerApi from "../../Api/Banner";
import NextNews from "../../Component/PageComponent/NextNews/NextNews";
import NewHotDetail from "../../Component/NewHotDetail/NewHotDetail";
import { Helmet } from "react-helmet";
import FormRegister from "../../Component/PageComponent/FormRegister/FormRegister";
const NewPostDetail = ({ targetRef }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const newPostId = id;
  const [newPostDetail, setNewPostDetail] = useState([]);
  const [listBannerCategory, setListBannerCategory] = useState([]);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const fetchListNewPostDetail =  async () => {
    try {
      const response = await PostNewsApi.GetNewPostDetail(newPostId, {});
      setNewPostDetail(response.data);
    } catch (err) {}
  };
  const fetchListBannerCategory = async () => {
    try {
      const response = await BannerApi.GetListBannerCategory(newPostId, {});
      setListBannerCategory(response.data);
    } catch (err) {}
  };
  useEffect(() => {
    fetchListNewPostDetail();
  }, [newPostId]);
  useEffect(() => {
    fetchListBannerCategory();
  }, []);


  return (
    <>
    <Helmet>
      <title>{newPostDetail[0]?.title}</title>
      <meta name="title" content={newPostDetail[0]?.meta_title}/>
      <meta name="description" content={newPostDetail[0]?.meta_description} />
      <meta name="keyword" content={newPostDetail[0]?.meta_keyword}/>
      <meta property="og:locale" content=""/>
      <meta property="og:type" content=""/>
      <meta property="og:description" content=""/>
      <meta property="og:url" content=""/>
      <meta property="og:site_name" content=""/>
      <meta property="og:img" content=""/>
    </Helmet>
      <div className="pageName pageNameMobile">
        <p>Tin tức / </p>
        <span> Trang tin</span>
      </div>
      <div className="containerNewDetail">
        <div className="navbarDetailLeft navbarDetailLeftMobile">
          {newPostDetail?.map((item, index) => (
            <div>
              <div>
                <h1 className="titileNewDetail titileNewDetailMobile">
                  {item.title}
                </h1>
                <div className="containerLock containerLockMobile">
                  <FontAwesomeIcon
                    className="iconLock iconLockMobile"
                    icon={faClock}
                  />
                  <p>{item.create_time}</p>
                </div>
                <h2 className="descriptionDetail descriptionDetailMobile">
                  {item.description}
                </h2>
              </div>
              <div className="contentDescriptionDetail" key={index}>
                <div
                  className="bodyContainerNewMobile"
                  dangerouslySetInnerHTML={{ __html: item.body }}
                ></div>
              </div>
            </div>
          ))}
          <div className="betweenNewPostDetail">
            <div className="buttonBackDetail buttonBackDetailMobile">
              <button
                onClick={() => {
                  navigate(`/tin-tuc`);
                  scrollToTop();
                }}
              >
                Trở lại
              </button>
            </div>
            <div className="socialNetwork socialNetworkMobile">
              <div className="text-share-new">
                <p>Chia sẻ bài viết hữu ích này qua</p>
              </div>
              <div className="IconNetWorkLink IconNetWorkLinkMobile">
                <FontAwesomeIcon
                  className="iconLinkNewDetail iconLinkNewDetailMobile"
                  icon={faSquareFacebook}
                />
                <FontAwesomeIcon
                  className="iconLinkNewDetail iconLinkNewDetailMobile"
                  icon={faXTwitter}
                />
                <FontAwesomeIcon
                  className="iconLinkNewDetail iconLinkNewDetailMobile"
                  icon={faInstagram}
                />
              </div>
            </div>
          </div>
          <div className="TitleNextNews TitleNextNewsMobile">
            <h1>Tin tiếp theo</h1>
          </div>
          <NextNews />
        </div>

        <div className="navbarDetailHotRight navbarDetailRightMobile">
          <div className="navbarNewHotRight">
            <div className="titleNewHot">
              <h2>Tin nóng</h2>
            </div>
            <NewHotDetail />
          </div>
          <div className="ContainerBannerCategory ContainerBannerCategoryMobile">
            {listBannerCategory.map((item, index) => (
              <div className="imgaeBanner" key={index}>
                <img alt="" src={item.banner_image_url} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <FormRegister targetRef={targetRef} />
      <GoTop />
      <ContactChild />
    </>
  );
};
export default NewPostDetail;
