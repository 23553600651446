import { NavLink, useNavigate } from "react-router-dom";
import * as React from "react";
import imgaeEnglish from "../../../asset/images/imgEnglish.png";
import imgaeVietNam from "../../../asset/images/imgVietnam.png";
import {useEffect, useState } from "react";
import MenuApis from "../../../Api/Menu";
import { CloseOutlined } from "@ant-design/icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Menu, Space } from "antd";
import "./header.scss";
import { useSelector } from "react-redux";
const items = [
  {
    label: (
      <div className="options-language">
        <img className="img-language" src={imgaeVietNam} alt="Vietnamese" />
        <p >Tiếng Việt</p>
      </div>
    ),
    key: "0",
  },
  // {
  //   label: (
  //     <div className="options-language">
  //       <img className="img-language" src={imgaeEnglish} alt="English" />
  //       <p >English</p>
  //     </div>
  //   ),
  //   key: "1",
  // },
];
const HeaderPage = ({ scrollToForm }) => {
  const navigate = useNavigate();
  const [listMenu, setListMenu] = useState([]);
  const [modleMneu, setModleMenu] = useState(false);
  const [isMenuVisible, setMenuVisible] = useState(true);
  const listInformation = useSelector((state)=>state.information.data);
  const [selectedItem, setSelectedItem] = useState({
    label: (
      <div className="options-language">
        <img className="img-language" src={imgaeVietNam} alt="Vietnamese" />
        <p className="title-language">Tiếng Việt</p>
      </div>
    ),
    key: "0",
  });
  const handlScrollToForm = () => {
    scrollToForm();
    setModleMenu(false);
  };
  const handleClickNextPage = () => {
    setModleMenu(false);
  };
  const handleClickMenu = () => {
    setModleMenu(!modleMneu);
  };

  const fetchListMenu = async (config = {}) => {
    try {
      const response = await MenuApis.GetAll(config);
      setListMenu(response.data);
    } catch (err) {
      throw err;
    }
  };
  const listMenuDependency = listMenu[0]?.lang_id;
  useEffect(() => {
    fetchListMenu();
  }, [listMenuDependency]);
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 250) {
        setMenuVisible(true);
      } else {
        setMenuVisible(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleCloseMobile = () => {
    setModleMenu(false);
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleItemClick = (items) => {
    setSelectedItem(items);
  };
  return (
    <>
      {isMenuVisible && (
        <div className="wapper wapperMobile">
          <div className="headerWaper headerWaperMobile">
            <div className="headerRight headerRightMobile">
              {listInformation &&
                listInformation?.map((item, index) => (
                  <div
                    className="headerLogo"
                    key={index}
                    onClick={() => {
                      navigate(`/`);
                      scrollToTop();
                    }}
                  >
                    <img alt="" src={item?.site_logo1_url} />
                  </div>
                ))}
              <ul className="headerMenu headerMenuMobile">
                {listMenu?.map((item) => {
                  return (
                    <li
                      className="menuContent"
                      key={item.category_id}
                      onClick={() => {
                        scrollToTop();
                      }}
                    >
                      <NavLink
                        to={item.category_meta_alias}
                        style={({ isActive }) => {
                          return {
                            color: isActive ? "#F46221 " : "",
                            borderBottom: isActive ? "3px solid #F46221" : "",
                            paddingBottom: isActive ? "5px" : "",
                          };
                        }}
                      >
                        {item.category_name}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="headerLeft headerLeftMobile">
              <div className="headerLanguage">
                <div className="header-select-img">
                  <Dropdown
                  className="options-dropdown-langugae"
                    overlay={
                      <Menu>
                        {items.map((item) => (
                          <Menu.Item key={item.key} onClick={() => handleItemClick(item)}>
                             <p className="text-language">{item.label}</p>  
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <Space className="title-language1" >{selectedItem.label}</Space>
                    </a>
                  </Dropdown>               
                </div>
              </div>
              <div className="risgterHeader risgterMobile">
                <button
                  className="risgterHeaderButton"
                  onClick={handlScrollToForm}
                >
                  Đăng kí dùng thử
                </button>
              </div>
              <div className="menuMobile">
                <FontAwesomeIcon
                  className="iconMenu"
                  icon={faBars}
                  onClick={handleClickMenu}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* menuMobile */}
      <div className="wapperMobile">
        {modleMneu && (
          <div className="header_wapper_menu_mobile">
            <div className="header_menu_mobile">
              <div className="navbarContentMenu">
                <ul className="menu_content_mobile">
                  {listMenu?.map((item, index) => (
                    <li
                      className="menuContentMobile"
                      onClick={() => {
                        handleClickNextPage();
                        scrollToTop();
                      }}
                      key={index}
                    >
                      <NavLink
                        to={item.category_meta_alias}
                        style={({ isActive }) => {
                          return {
                            color: isActive ? "#F46221" : "",
                          };
                        }}
                      >
                        {item.category_name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
                <div className="containerSelectLanguage">
                  <div className="titileLanguage">
                    <p>Chọn ngôn ngữ</p>
                  </div>
                  <div className="header-select-img-mobile">
                  <Dropdown
                  className="options-dropdown-langugae"
                    overlay={
                      <Menu>
                        {items.map((item) => (
                          <Menu.Item key={item.key}
                               onClick={() => handleItemClick(item)}
                          >
                            {item.label}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <Space>{selectedItem.label}</Space>
                    </a>
                  </Dropdown>                 
                  </div>
                </div>
                <div className="risgterMobile2">
                  <button onClick={handlScrollToForm}>Đăng kí dùng thử</button>
                </div>
              </div>
            </div>
            <div className="iconMenuMobile" onClick={handleCloseMobile}>
              <CloseOutlined />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default HeaderPage;
