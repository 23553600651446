import { useEffect, useRef, useState } from "react";
import "./nextNews.scss";
import { useNavigate, useParams } from "react-router";
import { Pagination } from "antd";
import PostNewsApi from "../../../Api/New";
const NextNews = () => {
  const { id } = useParams();
  const newNextRef = useRef(null);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const navigate = useNavigate();
  const [listNextNews, setListNextNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRows, setTotalRow] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [page, setPage] = useState(0)
  const fetchListNextNews = async (current) => {
    try {
      const response = await PostNewsApi.GetNextNews(id,current);
          setListNextNews(response.data.data);
          setTotalRow(response.data.total)
          setCurrentPage(response.data.current_page)
          setPageSize(response.data.per_page)
    } catch (err) {}
  };
  useEffect(() => {
    fetchListNextNews(page,id);
  }, [page,id]);
  const handleOnchangePage = (current) => {
    setPage(current);
    scrollTopNewNext();
  };
  const scrollTopNewNext = () => {
    if (newNextRef.current) { 
      const yOffset = -140; 
      const y = newNextRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
  
      window.scrollTo({
        top: y,
        behavior: 'smooth'
      });
    }
  };
  return (
    <>
      <div className="containerNextNews containerNextNewsMobile" ref={newNextRef} >
        {listNextNews.map((item) => {
          return (
            <div className="navbarNextNews navbarNextNewsMobile"
              
               onClick={()=>{navigate(`/tin-tuc/chi-tiet/${item.news_id}`)
               scrollToTop();
               }}>
              <div className="imgaeNextNews imgaeNextNewsMobile">
                <img alt="" src={item.news_thumb_image_url} />
              </div>
              <div className="contentNextNews contentNextNewsMobile">
                <div className="titileNextNews titileNextNewsMobile">
                  <h2>{item.title}</h2>
                </div>
                <div className="textNextNews textNextNewsMobile">
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>   
        <div className="nextNewPaing nextNewPaingMobile">
          <Pagination
            defaultCurrent={1}
            total={totalRows}
            current={currentPage}
            pageSize={pageSize}
            onChange={handleOnchangePage}
          />
        </div>
    </>
  );
};
export default NextNews;
