import AxiosClient from "./api";
const Site = process.env.REACT_APP_SITE_ID;
const GetAll = (config) =>{
    return AxiosClient.get(`reviewshomepage/${Site}/lang/1`, config)
}
const GetById = (id, config)=>{
    return AxiosClient.get(`/reviewshomepage/${id}`, config)
}
const ReviewCustomerApi = {GetAll,GetById};

export default ReviewCustomerApi