import { Navigate, Route, Routes, useParams  } from 'react-router-dom';
import './App.css';
import InformationApi from './Api/Information';
import FooterContext from './context/FooterContext';
import { useEffect, useRef, useState } from 'react';
import HeaderPage from './Component/PageComponent/HeaderPage/HeaderPage';
import Introduce from './page/Introduce/Introduce';
import Home from './page/Home/Home';
import Product from './page/Product/Product';
import Recruitment from './page/Recruitment/Recruitment';
import Contact from './page/Contact/Contact';
import News from './page/News/New';
import FooterPage from './Component/PageComponent/FooterPage/FooterPage';
import NewPostDetail from './page/NewPostDetail/NewPostDetail';
import ThemseBus from './page/ThemseBus/ThemesBus';
import ThemseDetail from './page/ThemseDetail/ThemseDetail';
import { useDispatch } from 'react-redux';
import { getListCategory } from './redux/Slice/menuSlice';
import { getListInformation } from './redux/Slice/informationSlice';
import { getListThemse } from './redux/Slice/themseBusSlice';
import TermsConditions from './page/TermsCondition/termsCondition';
import PrivacyPolicyes from './page/PrivacyPolicyes/privacyPolicyes';
import OperatingRegulations from './page/operatingRegulations/operatingRegulations';
import PrivacyPolicyeApp from './page/PrivacypolicyApp/privacyPolicyApp';



function App() {
const dispatch = useDispatch();

useEffect(()=>{
  dispatch(getListCategory());
},[dispatch]);

useEffect(()=>{
  dispatch(getListInformation());
},[dispatch]);

useEffect(()=>{
  dispatch(getListThemse());
},[dispatch]);
const targetRef = useRef(null);
const scrollToForm = () => {
  if (targetRef.current) { 
    const yOffset = -50; 
    const y = targetRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({
      top: y,
      behavior: 'smooth'
    });
  }
};
  return (
    <div>
            <HeaderPage scrollToForm={scrollToForm}  />      
            <Routes>
              <Route path="*" element={<Navigate to={"/"} />}/>
              <Route path="/" element={<Home scrollToForm={scrollToForm} targetRef={targetRef}/>}/>
              <Route path="/mau-web-nha-xe" element={<ThemseBus targetRef={targetRef} />}/>
              <Route path="/gioi-thieu" element={<Introduce targetRef={targetRef} />}/>
              <Route path="/san-pham" element={<Product scrollToForm={scrollToForm} targetRef={targetRef} />}/>
              <Route path="/tin-tuc" element={<News targetRef={targetRef} />}/>
              <Route path="/tuyen-dung" element={<Recruitment targetRef={targetRef} />}/>
              <Route path="/lien-he" element={< Contact targetRef={targetRef}/>}/>
              <Route path="/tin-tuc/chi-tiet/:id" element={< NewPostDetail targetRef={targetRef} />}/>
              <Route path="/mau-web-nha-xe/xem-chi-tiet/:id" element={< ThemseDetail targetRef={targetRef} />}/>
              <Route path="/dieu-khoan" element={< TermsConditions targetRef={targetRef} />}/>
              <Route path="/chinh-sach-bao-mat" element={< PrivacyPolicyes targetRef={targetRef} />}/>
              <Route path="/chinh-sach-quyen-rieng-tu" element={< PrivacyPolicyeApp targetRef={targetRef} />}/>
              <Route path="/quy-che-hoat-dong" element={< OperatingRegulations targetRef={targetRef} />}/>
            </Routes>
            <FooterPage/>
    
  </div>
  );
}

export default App;
