import { useEffect, useState } from 'react';
import "./goTop.scss";
import imgGoTop from '../../../asset/images/goTop.png';
const GoTop = ()=>{
    const [showScrollButton, setShowScrollButton] = useState()
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };
    useEffect(() => {
        const handleScroll = () => {
          const scrollY = window.scrollY;
          setShowScrollButton(scrollY > 150);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    return (
        <div>
            {showScrollButton && (

            <div className="wapperGoTop wapperGoTopMobile" onClick={scrollToTop}>
        
                    <img alt='' className="iconGoTop iconGoTopMobile" src={imgGoTop}/>
            </div>
            )}
        </div>
    )
}
export default GoTop;