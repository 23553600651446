import Register from '../RegisterPage/Register';
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OWINKS1 from "../../../asset/images/O.png";
import { useRef, useState } from 'react';
import Loading from '../Loading/Loading';
import './formRegister.scss'
const FormRegister = ({ targetRef }) => {
    const registerRef = useRef(null);
    const [hiddenRegister, setHiddenRegister] = useState(true);
    const [buttonRegister, setButtonRegister] = useState(false);
    const [iconBack, setIconBack] = useState(false);
    const handleClickButtonRegister = () => {
        setButtonRegister(!buttonRegister);
        setHiddenRegister(false);
        setIconBack(true);
      };
      const handleBackRegister = () => {
        setButtonRegister(false);
        setHiddenRegister(true);
        setIconBack(false);
        scrollToForm();
      };
      const scrollToForm = () => {
        if (targetRef.current) { 
          const yOffset = -300; 
          const y = targetRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      
          window.scrollTo({
            top: y,
            behavior: 'smooth'
          });
        }
      };
  return (
    <>
      <div className="wapperForm wapperFormMobile" ref={targetRef} >
        <div className="navbarForm navbarFormMobile">
          <div className="fromLeft">
            <div className="leftTitle leftTitleMobile">
              <p>ĐĂNG KÍ DÙNG THỬ</p>
              <div>
                <hr className="lineRegisterMobile" />
              </div>
            </div>
            <div
              className="formLeftImage formLeftImageMobile"
            >
              <img alt='' src={OWINKS1} />
            </div>
            <div
              className="formLeftContent formLeftContentMobile"
            >
              <p>
                Trải nghiệm demo hệ thống hỗ trợ quản lý và điều hành thuận
                tiện, nâng cao tốc độ vận hành hệ thống tạo đà tăng trưởng cho
                doanh nghiệp của bạn
              </p>
            </div>
            <div
              className="formLeftContentBottom formLeftContentBottomMobile"
            >
              <div className="containerTop">
                <div className="navbarContentBottom">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="iconNavbar"
                  />
                  <p> Quản lý thông minh</p>
                </div>
                <div className="navbarContentBottom">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="iconNavbar"
                  />
                  <p> Hỗ trợ sử dụng</p>
                </div>
              </div>
              <div className="containerBottom">
                <div className="navbarContentBottom">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="iconNavbar"
                  />
                  <p>Báo cáo chi tiết</p>
                </div>
                <div className="navbarContentBottom">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="iconNavbar"
                  />
                  <p>Nâng cao hiệu suất</p>
                </div>
              </div>
            </div>
          </div>
          
       {/* registerMobile */}

          <div className="formRegister">
            <div className="contentRegister">
              <Register ref={registerRef} />
            </div>
            {buttonRegister && (
              <div className="contentRegisterMobile">
                <Register />
              </div>
            )}
          </div>
          {hiddenRegister && (
            <div
              className="buttonRegisterMobile"
              onClick={handleClickButtonRegister}
            >
              <button>
                Điền thông tin đăng kí
                <DownOutlined className="iconRegisterDrop" />
              </button>
            </div>
          )}
          {iconBack && (
            <div
              className="iconBackRegister"
              onClick={handleBackRegister}
            >
              <UpOutlined />
            </div>
          )}
        </div>
      <Loading/>
      </div>
    </>
  );
};
export default FormRegister;
