import { Helmet } from "react-helmet";
import FormRegister from "../../Component/PageComponent/FormRegister/FormRegister";
import "./privacyPolicyesApp.scss";
const PrivacyPolicyeApp = ({ targetRef }) => {
  return (
    <div>
      <Helmet>
        <title>Chính sách quyền riêng tư App Carlink Driver</title>
        <meta name="description" content="Chính sách quyền riêng tư app Carlink Driver" />
        <meta property="og:locale" content="" />
        <meta property="og:type" content="" />
        <meta property="og:description" content="" />
        <meta property="og:url" content="" />
        <meta property="og:site_name" content="" />
        <meta property="og:img" content="" />
      </Helmet>

      <div className="Wapper-Privacy-Policyes">
        <div className="headerPolicyes">
            <h1
              style={{
                fontSize: "24px",
                fontFamily: "LexendRegular",
                color: "#333",
                padding: "0 10px",
              }}
            >
              Chính sách quyền riêng tư App Tài xế
            </h1>
        
            <h2 className="text-item">
            Carlink Driver tôn trọng quyền riêng tư của mỗi cá nhân, vì thế chúng tôi luôn cố gắng bảo vệ toàn bộ thông tin của người sử dụng. Chính sách về quyền riêng tư sẽ thể hiện quá trình chúng tôi thu thập, chuyển đổi, xử lý và sử dụng. 
            </h2>
        </div>
        <div>
          {/* <h2 className="text-item">1. Loại DLCN được thu thập và xử lý</h2> */}
          <div style={{ paddingLeft: "12px" }}>
            {/* <h3 className="texxt-item-child">a. DLCN của Bạn</h3> */}
            <div style={{ paddingLeft: "8px" }}>
              <p className="content-text-term">
              Bằng việc cung cấp thông tin cá nhân, bạn đã đồng ý và chấp nhận việc trao đổi, xử lý, sử dụng và công bố thông tin được đề cập tại chính sách này. 
              </p>
              <p className="content-text-term">
              Chúng tôi sẽ thu thập thông tin cá nhân mà bạn cung cấp, các thông tin này sẽ thể hiện rằng bạn là một người dùng cá nhân. Việc thu thập trên chỉ được thực hiện nếu bạn đồng ý yêu cầu của chúng tôi. Thông tin này bao gồm:
              </p>
              <p className="content-text-term">
                - Họ và tên 
              </p>
              <p className="content-text-term">
                - Số điện thoại 
              </p>
              <p className="content-text-term">
                - Ngày tháng năm sinh 
              </p>
              <p className="content-text-term">
                - Ảnh chân dung 
              </p>
              <p className="content-text-term">
                - Ảnh bằng lái  
              </p>
              <p className="content-text-term">
                - Địa chỉ Email ( nếu có )  
              </p>
            </div>
            <h3 className="texxt-item-child">
               Bằng việc cung cấp thông tin cá nhân, bạn đồng ý với việc nếu pháp luật tại địa phương hoặc tại nơi bạn nhận thông tin cho phép, chúng tôi sẽ dùng thông tin đó để thực hiện các công việc sau
            </h3>
            <div style={{ paddingLeft: "8px" }}>
            <p className="content-text-term">
                - Trả lời yêu cầu của bạn 
              </p>
              <p className="content-text-term">
              -	Cải thiện dịch vụ của chúng tôi  
              </p>
              <p className="content-text-term">
              -	Nâng cấp thông tin trên các phương tiện truyền thông 
              </p>
              <p className="content-text-term">
              -	Cung cấp cho bạn các thủ thuật, thông tin hữu ích, tin tức cập nhật mới về sản phẩm, ứng dụng
              </p>
              <p className="content-text-term">
              -	Thông báo về sản phẩm và dịch vụ mới
              </p>
              <p className="content-text-term">
              -	Giúp khách hàng có cái nhìn về sản phẩm và dịch vụ 
              </p>
              <p className="content-text-term">
              -	Cho các mục đích quản trị và đảm bảo chất lượng sản phẩm của chúng tôi 
              </p>
              <p className="content-text-term">
              -	Cho các mục đích khác được liệt kê chi tiết trên web hoặc các ứng dụng di động 
              </p>
            </div>
            <h3 className="texxt-item-child">
             Chúng tôi bảo mật thông tin như thế nào?
            </h3>
            <div style={{ paddingLeft: "8px" }}>
              <p className="content-text-term">
              Chúng tôi bảo mật thông tin cá nhân của bạn nhưng chúng tôi không đảm bảo sự an toàn các thông tin bạn tải lên ứng dụng di động. Bạn sẽ chịu rủi ro về các thông tin được tải lên. Vì chúng tôi không thể đảm bảo tuyệt đối không có việc rò rỉ thông tin, sử dụng sai mục đích hoặc thay đổi dữ liệu, nên ngay khi nhận được thông tin của bạn, chúng tôi sẽ sử dụng các biện pháp bảo mật chuyên môn để phòng tránh các sự cố nêu trên xảy ra.
              </p>
            </div>
          </div>
          <h2 className="text-item">Chia sẻ thông tin bên thứ 3</h2>
               
          <div style={{ paddingLeft: "8px" }}>
                <p className="content-text-term ">
                    Chúng tôi cam kết không chia sẻ thông tin của bạn sang đơn vị thứ 3 hay bất kể đơn vị nào khác. 
                </p>
          </div>
          <h2 className="text-item">Thay đổi chính sách</h2>
          <div style={{ paddingLeft: "12px" }}>
           
            <div style={{ paddingLeft: "8px" }}>
              <p className="content-text-term">
              Chính sách quyền riêng tư này sẽ được cập nhật trong tương lai. Nếu có sự thay đổi có tính chất quan trọng, chúng tôi sẽ đăng thông báo trên trang web hoặc các ứng dụng và các thỏa thuận cấp phép liên quan. Chúng tôi khuyết khích bạn nên thường xuyên tham khảo Chính sách  quyền Riêng tư để cập nhật thông tin mới nhất về việc chúng tôi bảo mật thông tin cá nhân thu thập được. Việc bạn tiếp tục sử dụng App đồng nghĩa với việc chấp thuận các điều khoản quyền riêng tư và các cập nhật của chính sách này. Những thay đổi trong chính sách không áp dụng với các dữ liệu được thu thập trước đó. 
              </p>
            </div>
          </div>
        </div>
      </div>
      <FormRegister targetRef={targetRef} />
    </div>
  );
};
export default PrivacyPolicyeApp;
