import { useEffect, useState } from "react";
import ReviewCustomerApi from "../../../Api/ReviewCustomer";
import "./reviewCustomer.scss";
import { ArrowRightOutlined } from "@ant-design/icons";
import Frame1 from "../../../asset/images/Khung.png";
const ReviewCustomer = () => {
  const [listReviews, setListReviews] = useState([]);
  const FetchListReviews = async (config = {}) => {
    try {
      const response = await ReviewCustomerApi.GetAll(config);
      setListReviews(response.data.data);
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    FetchListReviews();
  }, []);
  const listCutReview = listReviews.slice(0,2);
  const renderStars = (star) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span
          key={i}
          style={{
            cursor: "default",
            color: i <= star ? "#124EB0" : "gray",
            width: "12px",
            height: "12px",
          }}
        >
          &#9733;
        </span>
      );
    }
    return stars;
  };
  return (
    <>
      <div className="wapperReview wapperReviewMoblie">
        <div className="reviewsTitle">
          <p >Đánh Giá trải nghiệm</p>
          <h2 >Cảm nhận của khách hàng</h2>
        </div>
        <div className="navbarReview">
          {listReviews.map((item, index) => (
            <div className="containerReview" key={index}>
              <div className="frameContent">
                <img alt="" src={Frame1} />
              </div>
              <div className="reviewImage">
                <img alt="" src={item.customer_avatar_url} />
              </div>
              <div className="wapperContent">
                <div className="reviewContent">
                  <div className="customerName">
                    <p>{item.customer_name}</p>
                  </div>
                  <div className="reivewService">
                    <div>
                      <p>{item.customer_company}</p>
                      <div className="customerLine"></div>
                    </div>
                    <div className="quality">
                      {renderStars(item.star)}
                    </div>
                  </div>
                  <div className="ReviewText">
                    <p>{item.content}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="navbarReviewTablet">
          {listCutReview.map((item, index) => (
            <div className="containerReview" key={index}>
              <div className="frameContent">
                <img alt="" src={Frame1} />
              </div>
              <div className="reviewImage">
                <img alt="" src={item.customer_avatar_url} />
              </div>
              <div className="wapperContent">
                <div className="reviewContent">
                  <div className="customerName">
                    <p>{item.customer_name}</p>
                  </div>
                  <div className="reivewService">
                    <div>
                      <p>{item.customer_company}</p>
                      <div className="customerLine"></div>
                    </div>
                    <div className="quality">
                      {renderStars(item.star)}
                    </div>
                  </div>
                  <div className="ReviewText">
                    <p>{item.content}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="reviewBottom">
          <div className="bottomtLeft">
            <p>Bạn muốn biết thêm những cảm nhận của người khác</p>
          </div>
          <div className="bottonRight">
            <a>
              <p>Xem ngay</p>
              <ArrowRightOutlined />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReviewCustomer;
