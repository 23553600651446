
export const getCategory = (listCategory ,path )=>{
    const categoryId = listCategory?.length && listCategory?.find((category) =>{
        if(category?.category_meta_alias === '') {
            if(path === category?.category_meta_alias){
                return true;
                
            }      
        }
        else{
            return path.includes(category?.category_meta_alias) ;
        }
    });
    return categoryId?.category_id;
}
export const getListMetaSeo = (listCategory, path) => {
    const foundCategory = listCategory?.length && listCategory?.find((category)=>{

        if(category?.category_meta_alias === ''){
            if(path === category?.category_meta_alias){
                return true
            }
        } else {
            return path.includes(category?.category_meta_alias)
        }
       });
           return {
               category_meta_title: foundCategory?.category_meta_title,      
               category_meta_description: foundCategory?.category_meta_description,
               category_meta_keywords: foundCategory?.category_meta_keywords,
           }
        };

export const getListThemseBus = (listThemseBus, idThem) => {

    const foundThemseBus = listThemseBus?.length && listThemseBus?.find(themse => themse?.id == idThem );
    if (foundThemseBus) {
        return {
            themseId: foundThemseBus?.id,
            themseName: foundThemseBus?.name,
        };
    } else {
        return null; 
    }
};

