
import { combineReducers } from '@reduxjs/toolkit';
import menuSlice from './Slice/menuSlice';
import modalSlice from './Slice/modalSlice';
import informationSlice from './Slice/informationSlice';
import loadingSlice from "./Slice/loadingSlice";
import themseBusSlice from './Slice/themseBusSlice';
const rootReducer = combineReducers({
  menu: menuSlice,
  information : informationSlice,
  modal:modalSlice,
  loadding:loadingSlice,
  themseBus:themseBusSlice,
});


export default rootReducer;