import AxiosClient from "./api";
const Site = process.env.REACT_APP_SITE_ID;
const GetAll = (config) =>{
    return AxiosClient.get(`listaboutusbycategoryid/${Site}/lang/1`, config)
}
const GetlistAboutId = (id,config) =>{
  return AxiosClient.get(`listcorevaluesbyaboutusid/${id}/lang/1`, config)
}
const GetById = (id, config)=>{
    return AxiosClient.get(`aboutus/1${id}`, config)
}
const Create = (data, config) => {
    return AxiosClient.post("aboutus/1", data, config);
  };
  const Update = (id, data, config) => {
    return AxiosClient.put(`aboutus/1/${id}`, data, config);
  };
  const Delete = (id, config) => {
    return AxiosClient.delete(`aboutus/1${id}`, config);
  };
const AboutusApi = {GetAll,GetById,GetlistAboutId,Create,Update,Delete};

export default AboutusApi;