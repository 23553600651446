import { useEffect, useState} from "react";
import "./newHot.scss";
import { useLocation, useNavigate } from "react-router";
import PostNewsApi from "../../../Api/New";
import { useSelector } from "react-redux";
import { getCategory } from "../../../config/helper";
const NewHot = () => {
  const navigate = useNavigate();
  const [listNewHotCustom1, setListNewHotCustom1] = useState();
  const [listNewHotCustom2,setListNewHotCustom2] = useState();
  const [listNewHot, setListNewHot] = useState([]);
  const location = useLocation();
  const listCategory = useSelector((state)=> state.menu.data);
  const id = getCategory(listCategory, location?.pathname.slice(1))
  const fetchListNewHot = async () => {
    try {
      const response = await PostNewsApi.GetNewHot(id,{});
      setListNewHot(response.data);
    } catch (err) {
      throw err;
    }
  };
  useEffect(()=>{
      fetchListNewHot();
  },[])
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect (()=>{
    if(listNewHot){
      setListNewHotCustom1( listNewHot[0] );
      setListNewHotCustom2(listNewHot.slice(1, 3));
    }else{
        setListNewHotCustom1(null);
        setListNewHotCustom2(null);
         }
  },[listNewHot])
  return (
    <>
      <div className="titileNewHot titileNewHotMobile">
        <h1>Tin nóng</h1>
      </div>
      <div className="containerNewHot">
        <div className="navbarNewHotLeft navbarNewHotLeftMobile"
            onClick={()=>{navigate(`/tin-tuc/chi-tiet/${listNewHotCustom1?.news_id}`)
            scrollToTop();
        }} 
        >
          <div className="imgLeftNewHot imgLeftNewHotMobile ">
            <img alt="" src={listNewHotCustom1?.news_hot_image_url} />
          </div>
          <div className="navbarContentLeft navbarContentLeftMobile">
            <div className="titleContentLeft titleContentLeftMobile">
              <h2>{listNewHotCustom1?.title}</h2>
            </div>
            <div className="contentLeftNewHot contentLeftNewHotMobile">
              <p>{listNewHotCustom1?.description}</p>
            </div>
          </div>
        </div>
       {listNewHotCustom2 &&(
        <div className="navbarNewHotRight1 navbarNewHotRightMobile">
          {listNewHotCustom2.map((item) => (
            <div className="childContainerRight"
                onClick={()=>{navigate(`/tin-tuc/chi-tiet/${item.news_id}`)
                             scrollToTop();
            }} 
            >
              <div className="imgRightNewHot">
                <img alt="" src={item.news_hot_image_url} />
              </div>
              <div className="navbarContentRight">
                <div className="titleContentRight">
                  <h2>{item.title}</h2>
                </div>
                <div className="contentRightNewHot">
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
         )}
      </div>
 
    
    </>
  );
};
export default NewHot;
