import AxiosClient from "./api";
const Site = process.env.REACT_APP_SITE_ID;
const GetAll = (config) =>{
    return AxiosClient.get(`category/${Site}/lang/1`, config)
}
const GetCategoryId = ( id, config)=>{
    return AxiosClient.get(`/getcategorybyid/${id}`, config)
}
const MenuApis = {GetAll,GetCategoryId};

export default MenuApis