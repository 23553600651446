import GoTop from "../../Component/PageComponent/GoTop/GoTop";
import FormRegister from "../../Component/PageComponent/FormRegister/FormRegister";
import "./contact.scss";
import ContactChild from "../../Component/PageComponent/Contact/Contact";
import Questions from "../../Component/PageComponent/Questions/Questions";
import { useEffect, useState } from "react";
import ContactApi from "../../Api/Contact";
import { Form, Input, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import imgSuccess from '../../asset/images/success.png';
import imgError from '../../asset/images/error.png';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Component/PageComponent/Loading/Loading";
import { CloseLoading, openLoading } from "../../redux/Slice/loadingSlice";
import { getListMetaSeo } from "../../config/helper";
import { useLocation } from "react-router";
const Contact = ({ targetRef }) => {
  const dispath = useDispatch();
  const [form] = Form.useForm();
  const [listContactCategory, setListContactCategory] = useState([]);
  const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false);
  const [isModalOpenError, setIsModalOpenError] = useState(false);
  const [successe, setSuccess] = useState('');
  const [error, setError] = useState('');
  const location = useLocation();
  const listCategory = useSelector((state)=> state.menu.data);
  const listMetaSeo = getListMetaSeo(listCategory,location?.pathname.slice(1));
  const hiddenModal = () => {
    setIsModalOpenSuccess(false);
    setIsModalOpenError(false);
  }
  const fetchListContactCategory = async (config = {}) => {
    try {
      const response = await ContactApi.GetListContactCategory(config);
      setListContactCategory(response.data);
    } catch (err) { }
  };
  useEffect(() => {
    fetchListContactCategory();
  }, []);
  const onFinishFailed = (errorInfo) => {
  
  };
  const onFinish = async (values) => {
    try {
      const valueData = {
        ...values,
        site_id: 1,
        lang_id: 1,
      };
       dispath(openLoading());
      const response = await ContactApi.SubmitContact(valueData);
        
      if (response.status === 200) {
          dispath(CloseLoading());
          setIsModalOpenSuccess(true);
          setSuccess(response.data.message);
          form.resetFields();
       
        return response;
      }
      if (response.status === 429) {
        dispath(CloseLoading());
        setIsModalOpenSuccess(true);
        setSuccess(response.data.message);
      } else {
        dispath(CloseLoading());
        setIsModalOpenError(true);
        setError(response.data.message);
      }

    } catch (error) {
      dispath(CloseLoading());
      setIsModalOpenError(true);
      setError("Lỗi đăng kí xin vui lòng thử lại sau!");  
    }
  };
  return (
    
    <>
      {listMetaSeo && (<Helmet>
      <title>{listMetaSeo?.category_meta_title}</title>
      <meta name="description" content={listMetaSeo?.category_meta_description}/>
      <meta name="keywords" content={listMetaSeo?.category_meta_keywords}/>
      <meta property="og:locale" content=""/>
      <meta property="og:type" content=""/>
      <meta property="og:description" content=""/>
      <meta property="og:url" content=""/>
      <meta property="og:site_name" content=""/>
      <meta property="og:img" content=""/>
    </Helmet>)}
      <div
        className="containerHeader containerHeaderMobile"
      >
        {listContactCategory.map((item) => (
          <div
            className="headerContact headerContactMobile"
          >
            <p>{item.category_title}</p>
          </div>
        ))}
      </div>
      <div className="containerContact">
        {listContactCategory.map((item) => {
          return (
            <div className="bodyContact bodyContactMobile">
              <div className="bodyContact">
                <div className="contentContact"
                  dangerouslySetInnerHTML={{
                    __html: item.category_description,
                  }}
                >
                </div>

              </div>
            </div>
          )
        })}
        <div
          className="RegisterContact RegisterContactMobile"
        >
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="formContact"
          >
            <div
              className="inputRegister inputRegisterMobile"
            >
              <div className="inputLeft inputLeftMobile">
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "vui lòng nhập họ tên!",
                    },
                  ]}
                >
                  <Input placeholder="Họ tên*" />
                </Form.Item>
              </div>
              <div
                className="inputRight inputRightMobile"
              >
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập số điện thoại!",
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Vui lòng chỉ nhập số !",
                    },
                  ]}
                >
                  <Input placeholder="Số di động*" />
                </Form.Item>
              </div>
            </div>
            <div>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập địa chỉ email!",
                  },
                  {
                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Vui lòng nhập đúng email !",
                  },
                ]}
              >
                <Input placeholder="Nhập địa chỉ email" />
              </Form.Item>
            </div>

            <div>
              <Form.Item name="title">
                <Input placeholder="Nhập tiêu đề mail" />
              </Form.Item>
            </div>
            <div>
              <Form.Item name="message">
                <TextArea
                  className="textAreaContact"
                  row={6}
                  placeholder="Nhập lời nhắn bạn muốn để lại"
                />
              </Form.Item>
            </div>
            <button
              className="buttonRegisterContact buttonRegisterContactMobile"
              htmlType="submit"
            >
              Gửi đi
            </button>
          </Form>
        </div>
      </div>
      <div >
        <Modal open={isModalOpenSuccess} width={520} className="modalNotification modalNotificationMobile" >
          <img alt="" className="imageSuccses imageSuccsesMobile" src={imgSuccess} />
          <h3>Gửi thông tin thành công</h3>
          {imgSuccess && <p>{successe} </p>}
          <button onClick={hiddenModal} className="buttonConfirm buttonConfirmMobile">
            Đã Hiểu
          </button>
        </Modal>
      </div>
      <div >
        <Modal open={isModalOpenError} width={520} className="modalNotification modalNotificationMobile"  >
          <img alt="" className="imageSuccses imageSuccsesMobile" src={imgError} />
          <h3>Gửi thông tin thất bại</h3>
          {imgError && <p>{error} </p>}
          <button onClick={hiddenModal} className="buttonConfirm buttonConfirmMobile">
            Đã Hiểu
          </button>
        </Modal>
      </div>
      <Questions />
      <FormRegister targetRef={targetRef} />
      <ContactChild />
      <Loading/>
      <GoTop />
    </>
  );
};
export default Contact;
