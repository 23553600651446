import axios from "axios";
const baseURL = process.env.REACT_APP_URL_API;
const AxiosClient = axios.create({
  baseURL,
  headers:{
    'Content-Type': 'multipart/form-data'
  }
});
AxiosClient.interceptors.response.use((response) => {
    if (response.status === 200) ;
    return response;
  },
    (error)=>{
       return Promise.reject(error);
    }
  );
export default AxiosClient;