import * as React from "react";
import "./footerPage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import pay from "../../../asset/images/image 7.jpg";
import pay1 from "../../../asset/images/image 8.png";
import download from "../../../asset/images/image 9.png";
import download1 from "../../../asset/images/image 10.png";
import Bocongthuong from "../../../asset/images/bocongthuong.png";
import EnvironMent from "../../../asset/images/Environment.png";
import Phone from "../../../asset/images/phone.png";
import Email from "../../../asset/images/mail.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
const FooterPage = () => {
  const listInformation = useSelector((state) => state.information.data);
  // const handleGoogleMapsClick = () => {
  //   const googleMapsURL = "https://maps.app.goo.gl/bY8U5mBzNQf9pR8a8";
  //   window.open(googleMapsURL, "_blank");
  // };
  const hotiline =
    listInformation && listInformation?.map((item) => item.site_hotline);
  const handleClickCall = () => {
    const telLink = `tel:${hotiline}`;
    window.open(telLink, "_blank");
  };
  const navigate = useNavigate();
  const onclickTolinkDK =()=>{
    navigate('dieu-khoan');
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  const onclickTolinkBM =()=>{
    navigate('chinh-sach-bao-mat');
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const onclickTolinkQRT =()=>{
    navigate('chinh-sach-quyen-rieng-tu');
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const onclickTolinkHD =()=>{
    navigate('quy-che-hoat-dong');
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <>
      <div className="containerFooterMobile">
        {listInformation &&
          listInformation?.map((item, index) => (
            <div className="waper waperMobile" key={index}>
              <div className="footerWapper">
                <div className="footerContact">
                  <div className="footerLogo">
                    <img
                      className="image-logo"
                      alt="/"
                      src={item?.site_logo2_url}
                    />
                    <div className="text-number">
                      <p>Chuyển đổi số thành công !</p>
                    </div>
                  </div>
                  <ul className=" contactContent contactContentMobile">
                    <li
                      className="contactContentText contactContentTextMobile"
                      onClick={handleClickCall}
                    >
                      <img alt="/" src={Phone} />
                      <a href={item?.site_footer_phone}>
                        {item?.site_footer_phone}
                      </a>
                    </li>
                    <li className="contactContentText contactContentTextMobile">
                      <img alt="/" src={Email} />
                      <a href={item?.site_footer_email}>
                        {item?.site_footer_email}
                      </a>
                    </li>
                    <li
                      className="addreses-mapss contactContentText contactContentTextMobile"
                    >
                      <img alt="/" src={EnvironMent} />
                      <a href={item?.site_footer_address}>
                        {item?.site_footer_address}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="footerContacts footerContactsProduct">
                  <div className="footerTitle">
                    <p>Chính sách & quy định</p>
                    <div className="lineBottom"> </div>
                  </div>
                  <ul className="contentText">
                    <li className="rules" onClick={onclickTolinkDK}>
                       <p>Điều khoản & điều kiện</p>

                    </li>
                    <li className="rules" onClick={onclickTolinkBM}>
                       <p>Chính sách bảo mật</p>
                     
                    </li>
                    <li className="rules" onClick={onclickTolinkQRT}>
                       <p>Chính sách quyền riêng tư app Carlink Driver</p>
                     
                    </li>
                    <li  className="rules" onClick={onclickTolinkHD}>
                       <p>Quy chế hoạt động</p>
                     
                    </li>
                  </ul>
                </div>

                <div className="footerPaypay">
                  <div className="footerTitle footerTitleMobile">
                    <div className="navbarTititleFooter">
                      <p>Cổng thanh toán</p>
                      <div className="lineBottom"> </div>
                    </div>
                    <div className="footerWapperImage">
                      <div className="footerPay footerPayMobile">
                        <img alt="" src={pay} />
                      </div>
                      <div className="footerPay1">
                        <img alt="" src={pay1} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footerContacts">
                  <div className="footerTitle footerTitleMobile">
                    <div className="navbarTititleFooter">
                      <p>Tải xuống</p>
                      <div className="lineBottom"> </div>
                    </div>
                    <div className="footerWapperImage">
                      <div>
                        <a href={item.play_store_url}>
                          <img alt="" src={download} />
                        </a>
                      </div>
                      <div>
                        <a href={item.app_store_url}>
                          <img alt="" src={download1} />
                        </a>
                      </div>
                    </div>
                    <div className="footerImageMobileMore">
                      <img alt="" src={Bocongthuong} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="waperLine">
                <hr className="liness" />
              </div>
              <div className="navbarLink navbarLinkMobile">
                <div className="address-maps">
                  <ul style={{ padding: 0 }}>
                    <li
                      className="contactContentText contactContentTextMobile"
                      // onClick={handleGoogleMapsClick}
                    >
                      <img alt="" src={EnvironMent} />
                      <a href={item?.site_footer_address}>
                        {item?.site_footer_address}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="coppyRight coppyRightMobile">
                  <span>{item.site_footer_version}</span>
                </div>
                <div className="socialNetwork socialNetworkMobile">
                  <div className="netWorkIcon netWorkIconMobile">
                    <div className="iconNetWork">
                      <a
                        className="iconLink iconLinkMobile"
                        href={item.site_footer_social_url1}
                      >
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                    </div>
                    <div className="iconNetWork">
                      <a
                        className="iconLink iconLinkMobile"
                        href={item.site_footer_social_url2}
                      >
                        <FontAwesomeIcon icon={faXTwitter} />
                      </a>
                    </div>
                    <div className="iconNetWork">
                      <a
                        className="iconLink iconLinkMobile"
                        href={item.site_footer_social_url3}
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </div>
                  </div>
                  <div className="support supportMobile">
                    <span>Hỗ trợ</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};
export default FooterPage;
