import { Helmet } from "react-helmet";
import FormRegister from "../../Component/PageComponent/FormRegister/FormRegister";
import "./operatingRegulations.scss";
const OperatingRegulations = ({targetRef}) => {
  return (
    <>
      <Helmet>
        <title>Quy chế hoạt động</title>
        <meta name="description" content="Giới thiệu điều khoản và điều kiện" />
        <meta property="og:locale" content="" />
        <meta property="og:type" content="" />
        <meta property="og:description" content="" />
        <meta property="og:url" content="" />
        <meta property="og:site_name" content="" />
        <meta property="og:img" content="" />
      </Helmet>
      <div >
        <div className="wapperOperating">
          <h1 style={{fontSize:"24px", fontFamily:"LexendRegular", color:"#333"}}>QUY CHẾ HOẠT ĐỘNG WEBSITE CARLINK.COM.VN</h1>
          <h3 className="text-item">I. Nguyên tắc chung:</h3>
          <div style={{paddingLeft:"12px"}}>
          <p className="content-text-term">
          - Website Carlink.com.vn là sở hữu của CÔNG TY CỔ PHẦN CARLINK VIỆT NAM (“Công ty”) do Công ty phát triển, hoạt động và vận hành với tên miền giao dịch là www .Carlink.com.vn (sau đây gọi là Website). 
          </p>
          <p className="content-text-term">- Các đối tác kinh doanh của Carlink.com.vn phải là các nhà xe có hệ thống xe khách đảm bảo tiêu chuẩn, tiện nghi. Giá vé niêm yết, công khai. Lịch chạy linh hoạt, phục vụ đạt tiêu chuẩn. - Các đối tác kinh doanh của Carlink.com.vn phải là các nhà xe có hệ thống xe khách đảm bảo tiêu chuẩn, tiện nghi. Giá vé niêm yết, công khai. Lịch chạy linh hoạt, phục vụ đạt yêu cầu. </p>
          </div>
          <h3 className="text-item">II. Định nghĩa:</h3>
            <div style={{paddingLeft:"12px"}}>
              <p className="content-text-term"> <span style={{ fontWeight: "bold",fontSize:"16px"}}> Công ty :</span>  Là CÔNG TY CỔ PHẦN CARLINK VIỆT NAM.</p>
              <p className="content-text-term"> <span style={{ fontWeight: "bold",fontSize:"16px"}}>Cung cấp :</span>  Hệ thống điều hành quản lý xe khách trên toàn lãnh thổ Việt Nam. </p>
              <p className="content-text-term"> <span style={{ fontWeight: "bold",fontSize:"16px"}}>Người dùng :</span> Là chủ nhà xe, nhân viên nhà xe, hệ thống đại lý của nhà xe đủ 18 tuổi trở lên.</p>
            </div>
          <h3>III. Thông tin công ty: </h3>
          <h2 style={{fontSize:"22px", fontFamily:"LexendRegular", color:"#333", textAlign:"left"}} >CÔNG TY CỔ PHẦN CARLINK VIETNAM</h2>
          <div style={{paddingLeft:"12px"}}>
            <p className="content-text-term"> <span style={{ fontWeight: "bold",fontSize:"16px"}}>Địa chỉ :</span> Số 47a Ngõ 44 đường Trần Thái Tông, phường Dịch Vọng Hậu, quận Cầu Giấy, thành phố Hà Nội, Việt Nam </p>
            <p className="content-text-term"> <span style={{ fontWeight: "bold",fontSize:"16px"}}>Điện thoại :</span> 0962505028 </p>
            <p className="content-text-term"> <span style={{ fontWeight: "bold",fontSize:"16px"}}>Mã số thuế :</span> 0110529251</p>
            <p className="content-text-term"> <span style={{ fontWeight: "bold",fontSize:"16px"}}>Người đại diện :</span> NGUYỄN VĂN DŨNG</p>
          </div>
        <h3 className="text-item">IV:  Quy trình giao dịch: </h3>
        <div style={{paddingLeft:"12px"}}>
        <p className="content-text-term">Chúng tôi cung cấp hệ thống App website để người dùng ( Nhà xe / Đại lý) đăng nhập hệ thống để cập nhật về hệ thống xe khách của họ. Đồng thời sử dụng Website để bán vé, điều hành xe khách. Sử dụng báo cáo để thống kê doanh thu, nâng cao quy trình quản lý xe khách, chuyển đổi số toàn diện. </p>
        </div>
        </div>
      </div>
      <FormRegister targetRef={targetRef} />
    </>
  );
};
export default OperatingRegulations;
