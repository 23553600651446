import "./product.scss"
import FormRegister from "../../Component/PageComponent/FormRegister/FormRegister";
import {useEffect, useState } from "react";
import PartnerApi from "../../Api/Partners";
import StatisticalApi from "../../Api/Statistical";
import ProductApi from "../../Api/Product";
import MenuApis from "../../Api/Menu";
import GoTop from "../../Component/PageComponent/GoTop/GoTop";
import Contact from "../../Component/PageComponent/Contact/Contact";
import { Carousel, Collapse } from "antd";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { getCategory, getListMetaSeo } from "../../config/helper";
const Product = ({ targetRef, scrollToForm }) => {
  const { Panel } = Collapse;
  const [listPartner, setListPartner] = useState([]);
  const [listStatistical, setListStatistical] = useState([]);
  const [listExtraProduct, setListExtraProduct] = useState([]);
  const [listProductById, setListProductById] = useState([]);
  const [listProductByMenuId, setListProductByMenuId] = useState([]);
  const location = useLocation();
  const listCategory = useSelector((state)=> state.menu?.data);
  const id = getCategory(listCategory, location?.pathname?.slice(1));
  const listMetaSeo = getListMetaSeo(listCategory,location?.pathname?.slice(1));
  const scrollToTarget = () => {
    scrollToForm();
  };
  const fetchListPartner = async (config = {}) => {
    try {
      const response = await PartnerApi.GetProductByCategoryId(config);
      setListPartner(response?.data?.data);
    } catch (err) {
      throw err;
    }
  };
  const fetchListStatistical = async (config = {}) => {
    try {
      const response = await StatisticalApi.GetAll(config);
      setListStatistical(response?.data?.data);
    } catch (err) {
      throw err;
    }
  };
  const fetchListlistExtraProduct = async (config = {}) => {
    try {
      const response = await ProductApi.GetExtraProduct(config);
      setListExtraProduct(response?.data);
    } catch (err) {
      throw err;
    }
  };
  const fetchListlistProductById = async () => {
    try {
      const response = await ProductApi.GetByIdProduct(id, {});
      setListProductById(response?.data);
    } catch (err) {
      throw err;
    }
  };
  const fetchlistProductByMenuId = async () => {
    try {
      const response = await MenuApis.GetCategoryId(id, {});
      setListProductByMenuId(response?.data);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    fetchListPartner();
  }, []);
  useEffect(() => {
    fetchListStatistical();
  }, []);
  useEffect(() => {
    fetchListlistExtraProduct();
  }, []);
  useEffect(() => {
    fetchListlistProductById();
  }, [id]);
  useEffect(() => {
    fetchlistProductByMenuId();
  }, [id]);
  return (
    <>
    <Helmet>
    <title>{listMetaSeo?.category_meta_title}</title>
      <meta name="description" content={listMetaSeo?.category_meta_description}/>
      <meta name="keywords" content={listMetaSeo?.category_meta_keywords}/>
      <meta property="og:locale" content=""/>
      <meta property="og:type" content=""/>
      <meta property="og:description" content=""/>
      <meta property="og:url" content=""/>
      <meta property="og:site_name" content=""/>
      <meta property="og:img" content=""/>
    </Helmet>
      {listProductByMenuId &&
     listProductByMenuId?.length && listProductByMenuId?.map((item) => (
          <div
            className="introduceProduct introduceProductMobile"
          >
            <h1>{item?.category_title}</h1>
            <p>{item?.category_description} </p>
          </div>
        ))}
      <div className="containerProduct">
        {listProductById &&
          listProductById?.map((item, index) => (
            <div className="navbarProduct" key={index}>
              <div className="titleProduct">
                <p>{item?.product_title}</p>
              </div>
              <div className="contentProduct">
                <p>{item?.product_description}</p>
              </div>
              <div className="imageProduct">
                <img alt="" src={item?.product_image_url1} />
              </div>
            </div>
          ))}
      </div>
      <div className="containerProductMobile">
        <Collapse defaultActiveKey={['0']}>
          {listProductById.map((item) => (
            <Panel
              className="headerQuestionProduct"
              header={item?.product_title}
              key={item?.partner_id}
            >
              <p>{item?.product_description}</p>
              <img
                alt=""
                className="imgProductMobile"
                src={item?.product_image_url1}
              />
            </Panel>
          ))}
        </Collapse>
      </div>
      <div
        className="buttonRegisterProduct buttonRegisterProductMobile"
      >
        <button className="buttonRisgterHover" onClick={scrollToTarget}>
          Đăng kí dùng thử ngay
        </button>
      </div>
      { listStatistical?.length && listStatistical?.map((item) => (
        <div
          className="WapperStatistical WapperStatisticalMobile"
        >
          <h1 className="titileText titileTextMobile">
            Những con số ấn tượng
          </h1>
          <div
            className="containerStatisticalProduct containerStatisticalProductMobile"
          >
            <div
              className="navbarStatistical navbarStatisticalMobile"
            >
              <div
                className="StatisticaTitileType1 StatisticaTitileTypeMobile"
              >
                <h1>{item?.statistics_name1}</h1>
              </div>
              <div
                className="statisticalContent statisticalContentMobile"
              >
                <p>{item?.statistics_description1}</p>
              </div>
            </div>
            <div
              className="navbarStatistical navbarStatisticalMobile"
            >
              <div
                className="StatisticaTitileType2 StatisticaTitileTypeMobile1"
              >
                <h1>{item?.statistics_name2}</h1>
              </div>
              <div
                className="statisticalContent statisticalContentMobile"
              >
                <p>{item?.statistics_description2}</p>
              </div>
            </div>
            <div
              className="navbarStatistical navbarStatisticalMobile"
            >
              <div
                className="StatisticaTitileType1 StatisticaTitileTypeMobile"
              >
                <h1>{item?.statistics_name3}</h1>
              </div>
              <div
                className="statisticalContent statisticalContentMobile"
              >
                <p>{item?.statistics_description3}</p>
              </div>
            </div>
            <div
              className="navbarStatistical navbarStatisticalMobile"
            >
              <div
                className="StatisticaTitileType2 StatisticaTitileTypeMobile"
              >
                <h1>{item?.statistics_name4}</h1>
              </div>
              <div
                className="statisticalContent statisticalContentMobile"
              >
                <p>{item?.statistics_description4}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div
        className="TitileExtraProduct TitileExtraProductMobile"
      >
        <h2>Điều gì tạo nên sản phẩm của CarLink</h2>
        <p>
          Rất nhiều yếu tố góp phần tạo nên phần mềm như hiện tại, chúng tôi
          luôn sẵn sàng đón nhận các yêu cầu của Khách hàng. Chúng tôi luôn quan
          tâm đến trải nghiệm khi sử dụng phần mềm, chúng tôi đem lại những giá
          trị chuyển đổi số toàn diện đối với ngành xe khách vận tải.
        </p>
      </div>
      <div
        className="containerExtraProduct containerExtraProductMobile"
      >
        { listExtraProduct?.length && listExtraProduct?.map((item) => (
          <div
            className="navbarExtraProduct navbarExtraProductMobile"
          >
            <div className="extraProductImgae">
              <img alt="" src={item?.product_image_url1} />
            </div>
            <div
              className="contentTitleExtraProduct contentTitleExtraProductMobile"
            >
              <p>{item?.product_title}</p>
            </div>
            <div
              className="contentTextExtraProduct contentTextExtraProductMobile"
            >
              <p>{item?.product_description}</p>
            </div>
          </div>
        ))}
      </div>
      <div
        className="containerPartner containerPartnerMobile"
      >
        <h1 className="titlePartner titlePartnerMobile">
          Được tin dùng bởi các nhà xe và đối tác lớn
        </h1>
        <div className="wapperPart">
          <div className="slideProduct">
            <Carousel
              slidesToShow={5}
              autoplay
              arrows
            >
              {listPartner?.length &&listPartner?.map((item, index) => (
                <div
                  className="navbarPartner navbarPartnerMob"
                  key={index}
                >
                  <div
                    className="contentImage contentImageMobile"
                  >
                    <img alt="" src={item?.partner_logo_url} />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          <div className="slideProductTablet">
            <Carousel
              slidesToShow={3}
              autoplay
              arrows
            >
              { listPartner?.length && listPartner?.map((item, index) => (
                <div
                  className="navbarPartner navbarPartnerMob"
                  key={index}
                >
                  <div
                    className="contentImage contentImageMobile"
                  >
                    <img alt="" src={item?.partner_logo_url} />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          <div className="slideProductMobile">
            <Carousel slidesToShow={2}
             autoplay  
             arrows>
              {listPartner?.length && listPartner?.map((item, index) => (
                <div
                  className="navbarPartner navbarPartnerMob"
                  key={index}
                >
                  <div
                    className="contentImage contentImageMobile"
                  >
                    <img alt="" src={item?.partner_logo_url} />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      <FormRegister targetRef={targetRef} />
      <Contact />
      <GoTop />
    </>
  );
};
export default Product;
