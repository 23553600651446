import AxiosClient from "./api";
const Site = process.env.REACT_APP_SITE_ID;
const GetAll = (config) =>{
    return AxiosClient.get(`banner/${Site}`, config)
}
const GetListContactCategory = ( config)=>{
    return AxiosClient.get(`getcategorybyid/5`, config)
}
const SubmitContact = (data, config) => {
    return AxiosClient.post("submitcontact", data, config);
  };
  const Update = (id, data, config) => {
    return AxiosClient.put(`banner/1/${id}`, data, config);
  };
  const Delete = (id, config) => {
    return AxiosClient.delete(`banner/1${id}`, config);
  };
const ContactApi = {GetAll,GetListContactCategory,SubmitContact,Update,Delete};

export default ContactApi