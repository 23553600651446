import { Helmet } from "react-helmet";
import FormRegister from "../../Component/PageComponent/FormRegister/FormRegister";
import "./privacyPolicyes.scss";
const PrivacyPolicyes = ({ targetRef }) => {
  return (
    <div>
      <Helmet>
        <title>Chính sách bảo mật</title>
        <meta name="description" content="Giới thiệu điều khoản và điều kiện" />
        {/* <meta name="keywords" content={listInformation[0]?.site_keywords} /> */}
        <meta property="og:locale" content="" />
        <meta property="og:type" content="" />
        <meta property="og:description" content="" />
        <meta property="og:url" content="" />
        <meta property="og:site_name" content="" />
        <meta property="og:img" content="" />
      </Helmet>

      <div className="Wapper-Privacy-Policyes">
        <h1
          style={{
            fontSize: "24px",
            fontFamily: "LexendRegular",
            color: "#333",
          }}
        >
          Chính sách bảo mật
        </h1>
        <p className="content-privacyPolicyes">
          CÔNG TY CỔ PHẦN CARLINK VIỆT NAM (“Chúng Tôi”) coi trọng việc bảo vệ
          Dữ Liệu Cá Nhân (“DLCN”) của Bạn. Sau khi đọc Chính Sách Bảo Mật Thông
          Tin (“Chính Sách”), Bạn có toàn quyền quyết định việc chia sẻ DLCN với
          Chúng Tôi. Tùy từng thời điểm, Chúng Tôi có thể chỉnh sửa Chính Sách
          này để cập nhật những thay đổi của pháp luật, các tính năng của hệ
          thống công nghệ. Nếu Bạn tiếp tục đăng nhập, sử dụng website của Chúng
          Tôi, sẽ đồng nghĩa với việc Bạn chấp nhận nội dung của Chính Sách này
          và những thay đổi của Chính Sách tại từng thời điểm. Nếu Bạn sử dụng
          Website để thực hiện việc đặt vé xe khách cho một bên thứ ba, Bạn có
          trách nhiệm thông báo và đạt được sự chấp thuận của họ về việc Chúng
          Tôi sẽ thu thập và xử lý DLCN của họ. Chúng Tôi sẽ được miễn trừ trách
          nhiệm khỏi tất cả các yêu cầu, khiếu nại, khiếu kiện của bên thứ ba
          liên quan tới việc xử lý DLCN như đã nêu tại Chính Sách này. Dữ liệu
          cá nhân (“DLCN”) là thông tin dưới dạng ký hiệu, chữ viết, chữ số,
          hình ảnh, âm thanh hoặc dạng tương tự trên môi trường điện tử gắn liền
          với một con người cụ thể hoặc giúp xác định một con người cụ thể. Dữ
          liệu cá nhân bao gồm dữ liệu cá nhân cơ bản và dữ liệu cá nhân nhạy
          cảm. Xử Lý DLCN là một hoặc nhiều hoạt động tác động tới dữ liệu cá
          nhân, như: thu thập, ghi, phân tích, xác nhận, lưu trữ, chỉnh sửa,
          công khai, kết hợp, truy cập, truy xuất, thu hồi, mã hóa, giải mã, sao
          chép, chia sẻ, truyền đưa, cung cấp, chuyển giao, xóa, hủy dữ liệu cá
          nhân hoặc các hành động khác có liên quan.
        </p>
        <div>
          <h2 className="text-item">1. Loại DLCN được thu thập và xử lý</h2>
          <div style={{ paddingLeft: "12px" }}>
            <h3 className="texxt-item-child">a. DLCN của Bạn</h3>
            <div style={{ paddingLeft: "8px" }}>
              <p className="content-text-term">
                - Dữ liệu liên hệ của Bạn, bao gồm nhưng không hạn chế: họ, chữ
                đệm và tên khai sinh; ngày tháng năm sinh; giới tính; số điện
                thoại, email cá nhân; địa chỉ; thông tin nhận dạng.
              </p>
              <p className="content-text-term">
                - Thông tin về nhà xe mà bạn cung cấp bao gồm: Hình ảnh dữ liệu
                về xe, dữ liệu về chuyến đi, lịch chạy, dữ liệu về tài xế, nhân
                viên thông tin cá nhân. Dữ liệu về khách hàng đặt vé trên hệ
                thống.
              </p>
              <p className="content-text-term">
                - Dữ liệu về vị trí: Nhằm mục đích kiểm tra chính xác vị trí bạn
                đang ở để phục vụ cho nhu cầu vị trí về địa điểm đón trả, văn
                phòng nhà xe nơi bạn đang làm việc.
              </p>
              <p className="content-text-term">
                - Dữ liệu khác: địa chỉ IP, thời gian truy cập và sử dụng
                Website, thông tin nhấp chuột (click) và những trang thông tin
                mà Bạn đã xem.
              </p>
              <p className="content-text-term">
                - Cookies và các công nghệ tương tự: Chúng tôi sử dụng cookies
                và/hoặc các công nghệ tương tự để máy tính và/hoặc thiết bị di
                động nhận ra Bạn khi Bạn sử dụng Website của Chúng Tôi trong các
                lần tiếp theo.
              </p>
            </div>
            <h3 className="texxt-item-child">
              b. DLCN của người khác mà Bạn cung cấp cho Chúng Tôi
            </h3>
            <div style={{ paddingLeft: "8px" }}>
              <p className="content-text-term">
                Bạn có thể sử dụng website để đặt vé xe khách cho mình hoặc cho
                một bên thứ ba. Chúng Tôi sẽ thu thập DLCN của bên thứ ba do Bạn
                cung cấp cho Chúng Tôi. Bạn có trách nhiệm thông báo và đạt được
                sự chấp thuận của bên thứ ba về việc Chúng Tôi sẽ thu thập DLCN
                của họ phù hợp với nội dung quy định tại Chính Sách này.
              </p>
            </div>
            <h3 className="texxt-item-child">
              c. DLCN mà Chúng Tôi thu thập được từ nguồn khác
            </h3>
            <div style={{ paddingLeft: "8px" }}>
              <p className="content-text-term">
                Các sản phẩm và dịch vụ được hiển thị trên website của Chúng Tôi
                cũng có thể được hiển thị/ tích hợp vào các trang thương mại
                điện tử khác của các đối tác của Chúng Tôi. Vì vậy, Chúng Tôi có
                thể thu thập DLCN của Bạn từ các nguồn khác, bao gồm nhưng không
                giới hạn ở: đối tác kinh doanh, đối tác liên kết, công ty con và
                các bên thứ ba độc lập khác.
              </p>
            </div>
          </div>
          <h2 className="text-item">2. Mục đích Xử Lý DLCN</h2>
          <p className="content-text-term">
            DLCN của Bạn được Chúng Tôi thu thập và xử lý cho các mục đích dưới
            đây:
          </p>
          <div style={{ paddingLeft: "8px" }}>
            <p className="content-text-term">
              <span style={{ fontWeight: "bold" }}> (I).</span> Đăng ký và sử
              dụng Website: Chúng Tôi sử dụng thông tin Bạn cung cấp để thực
              hiện đăng ký tài khoản mới, xác thực thông tin tài khoản khi truy
              cập, sử dụng Website.
            </p>
            <p className="content-text-term">
              <span style={{ fontWeight: "bold" }}> (II).</span> Dịch vụ khách
              hàng: bộ phận Chăm sóc Khách hàng của Chúng Tôi sẽ liên hệ với Bạn
              khi cần thông báo về dữ liệu mà Bạn đã cập nhật trên hệ thống
              website; giải quyết các khiếu nại, tranh chấp nếu có liên quan tới
              Website do Chúng Tôi quản lý, vận hành; hoặc hỗ trợ Bạn làm việc
              với nhà cung cấp để giải quyết khiếu nại, thắc mắc (nếu có). Khi
              Bạn liên hệ với bộ phận Chăm sóc Khách hàng của Chúng Tôi, cuộc
              gọi của Bạn có thể được nghe trực tiếp hoặc được ghi âm phục vụ
              cho mục đích chăm sóc khách hàng hoặc giải quyết khiếu nại (nếu
              có).
            </p>
            <p className="content-text-term">
              <span style={{ fontWeight: "bold" }}> (III).</span> Cải thiện chất
              lượng Website: Chúng Tôi sử dụng thông tin Bạn cung cấp để nâng
              cao trải nghiệm của Bạn khi sử dụng Website của Chúng Tôi.
            </p>
            <p className="content-text-term">
              <span style={{ fontWeight: "bold" }}> (IV). </span> Thực hiện hoạt
              động tiếp thị và nghiên cứu thị trường: Chúng Tôi sẽ giới thiệu
              đến Bạn thông tin về các sản phẩm dịch vụ của Chúng Tôi và/hoặc
              nhà cung cấp thông qua việc gửi email, gửi tin nhắn, gửi thông báo
              vào tài khoản của Bạn trên Website. Bạn có quyền từ chối các tài
              liệu tiếp thị của Chúng Tôi bằng cách làm theo hướng dẫn hủy đăng
              ký được nêu trong tài liệu.
            </p>
            <p className="content-text-term">
              <span style={{ fontWeight: "bold" }}> (V). </span> Các mục đích
              khác sẽ được Chúng Tôi thông báo tới Bạn tại thời điểm thu thập
              thông tin.
            </p>
          </div>
          <h2 className="text-item">3. Cách thức xử lý DLCN</h2>
          <div style={{ paddingLeft: "12px" }}>
            <h3 className="texxt-item-child"> a. Cách thức thu thập DLCN</h3>
            <div style={{ paddingLeft: "8px" }}>
              <p className="content-text-term">
                - Chúng Tôi thực hiện thu thập DLCN của Bạn khi Bạn liên hệ với
                chúng tôi qua điện thoại, email, các kênh truyền thông khác như
                mạng xã hội; khi Bạn sử dụng dịch vụ qua Website của chúng tôi.
              </p>

              <p className="content-text-term">
                - Chúng Tôi thực hiện thu thập Dữ liệu khác của Bạn ngay cả khi
                Bạn không tạo tài khoản trên Website.
              </p>

              <p className="content-text-term">
                - Chúng tôi có thể thu thập DLCN của bạn từ bên thứ ba khác như:
                các nhà cung cấp dịch vụ, đối tác, các bên liên kết và bên thứ
                ba có liên quan đến hoạt động kinh doanh của Chúng tôi; hoặc từ
                các bên thứ ba có quan hệ với Bạn như người sử dụng lao động, tổ
                chức bạn là thành viên, …; hoặc từ các nền tảng mạng xã hội
                (google, BHXH, MST, Facebook, Zalo…) mà Bạn đã cập nhật.
              </p>
            </div>
            <h3 className="texxt-item-child">b. Cách thức xử lý DLCN</h3>
            <div style={{ paddingLeft: "8px" }}>
              <p className="content-text-term">
                Các hoạt động Xử Lý DLCN sẽ được Chúng Tôi xử lý theo một trong
                các cách sau: tự động hoặc không tự động; thông qua các phương
                tiện điện tử hoặc bằng cách thủ công hoặc bất kỳ phương thức nào
                khác mà Chúng Tôi cho là phù hợp.
              </p>
            </div>
          </div>
          <h2 className="text-item">
            4. Tổ chức, cá nhân được xử lý DLCN và các tổ chức, cá nhân khác có
            liên quan đến mục đích Xử Lý DLCN
          </h2>
          <div style={{ paddingLeft: "12px" }}>
            <p className="content-text-term">
              {" "}
              - Cán bộ nhân viên của Công ty Cổ phần Carlink Việt Nam.{" "}
            </p>
            <p>
              - Các cơ quan thực thi pháp luật nhằm điều tra hành vi vi phạm
              pháp luật, phòng chống gian lận khi có yêu cầu.
            </p>
            <p className="content-text-term">
              - Trường hợp chuyển nhượng doanh nghiệp: nếu Chúng Tôi thay đổi
              quyền sở hữu doanh nghiệp, Chúng Tôi sẽ thông báo với chủ sở hữu
              mới rằng họ chỉ được sử dụng thông tin của Bạn phù hợp với nội
              dung quy định tại Chính Sách này.
            </p>
            <p className="content-text-term">
              {" "}
              - Tùy thuộc vào vị trí của Bạn và địa điểm sử dụng sản phẩm, dịch
              vụ mà Bạn đã đặt trên Website, Chúng Tôi có thể sẽ chuyển DLCN của
              Bạn ra nước ngoài. Chúng Tôi sẽ thực hiện các biện pháp nghiệp vụ
              cần thiết và phù hợp để bảo vệ những thông tin này. Đồng thời,
              Chúng Tôi cũng yêu cầu bên thứ ba nhận dữ liệu phải áp dụng các
              biện pháp bảo mật cần thiết theo quy định của pháp luật.
            </p>
            <p className="content-text-term">
              {" "}
              - Bất kỳ tổ chức, cá nhân nào Chúng Tôi xét thấy là cần thiết vì
              mục đích xử lý dữ liệu cá nhân nêu tại Chính sách này.
            </p>
          </div>
          <h2 className="text-item">5. Thời gian lưu giữ DLCN</h2>
          <div style={{ paddingLeft: "12px" }}>
            <p className="content-text-term">
              DLCN của Bạn sẽ bắt đầu được xử lý kể từ khi Bạn cung cấp DLCN cho
              Chúng Tôi. Chúng Tôi sẽ lưu giữ DLCN của Bạn trong thời gian cần
              thiết để xử lý; và/hoặc để giải quyết các khiếu nại, tranh chấp;
              và/hoặc cho bất kỳ mục đích liên quan và hợp pháp nào khác. Chúng
              Tôi sẽ ngừng lưu giữ DLCN của Bạn ngay khi: (i) pháp luật hiện
              hành không yêu cầu lưu giữ các DLCN này; và/hoặc (ii) Chúng Tôi
              không còn mục đích kinh doanh hoặc mục đích hợp pháp khác để xử lý
              DLCN của Bạn; và/hoặc (iii) Bạn có yêu cầu rút lại sự đồng ý, yêu
              cầu xóa DLCN đã được Chúng Tôi thu thập trước đó.
            </p>
          </div>
          <h2 className="text-item">
            6.Chỉnh sửa thông tin, rút lại sự chấp thuận và yêu cầu xóa thông
            tin
          </h2>
          <div style={{ paddingLeft: "12px" }}>
            <p className="content-text-term">
              Nếu Bạn muốn chỉnh sửa bất kỳ DLCN nào đã cung cấp, vui lòng đăng
              nhập vào tài khoản của Bạn trên Website hoặc liên hệ với bộ phận
              Chăm sóc Khách hàng của Chúng Tôi.
            </p>
            <p className="content-text-term">
              Nếu Bạn muốn rút lại sự chấp thuận cho Chúng Tôi thu thập, xử lý
              DLCN hoặc yêu cầu Chúng Tôi xóa DLCN của Bạn, vui lòng gửi thông
              báo bằng văn bản tới Chúng Tôi thông qua địa chỉ email
              sales@carlink.com.vn. Theo yêu cầu của Bạn, Chúng Tôi sẽ ngừng thu
              thập, xử lý DLCN của Bạn và xóa các DLCN mà Bạn đã chia sẻ trừ
              trường hợp pháp luật có yêu cầu hoặc Chúng Tôi có mục đích hợp
              pháp khác cần lưu giữ những thông tin này.
            </p>
            <p className="content-text-term">
              Nếu Bạn yêu cầu rút lại sự chấp thuận với việc xử lý DLCN hoặc yêu
              cầu xóa DLCN, Bạn sẽ không thể tiếp tục sử dụng Website và Chúng
              Tôi và/hoặc Nhà Cung Cấp sẽ không thể cung cấp sản phẩm, dịch vụ
              theo yêu cầu của Bạn. Chúng Tôi không chịu trách nhiệm về bất kỳ
              tổn thất, thiệt hại nào với sự chấm dứt này. Yêu cầu rút lại sự
              đồng ý, xóa hoặc yêu cầu xóa DLCN của Bạn không ảnh hưởng đến tính
              hợp pháp của việc xử lý dữ liệu đã được đồng ý trước đó.
            </p>
          </div>
          <h2 className="text-item">
            7. Hậu quả, thiệt hại không mong muốn có khả năng xảy ra khi Xử Lý
            DLCN
          </h2>
          <div style={{ paddingLeft: "12px" }}>
            <p className="content-text-term">
              Chúng Tôi sẽ bảo vệ DLCN của Bạn bằng cách thực hiện các biện pháp
              bảo mật cần thiết và hợp lý bao gồm các quy trình vật lý, kỹ thuật
              và tổ chức, để ngăn chặn các hành vi truy cập, thu thập, sử dụng,
              tiết lộ, sao chép, sửa đổi, xử lý thông tin trái phép hoặc các rủi
              ro tương tự. Nếu có bất kỳ hành vi nào vi phạm DLCN của Bạn, Chúng
              Tôi sẽ thông báo cho Bạn. Đồng thời, Chúng Tôi sẽ nỗ lực để phục
              hồi tính bảo mật của DLCN mà Bạn đã chia sẻ trên Nền Tảng.
            </p>
            <p className="content-text-term">
              Bạn cần đảm bảo DLCN cung cấp cho Chúng Tôi là chính xác. Trong
              trường hợp DLCN được cung cấp không chính xác, Chúng Tôi có quyền
              tạm ngừng hoặc ngừng cung cấp dịch vụ theo chính sách của Chúng
              Tôi và các bên có liên quan.
            </p>
          </div>
          <h2 className="text-item">8. Quyền và nghĩa vụ của Bạn</h2>
          <div style={{ paddingLeft: "12px" }}>
            <p className="content-text-term">
              Bạn có các quyền: được biết, đồng ý, truy cập, rút lại sự đồng ý,
              yêu cầu xóa dữ liệu, hạn chế xử lý dữ liệu, cung cấp dữ liệu, phản
              đối xử lý dữ liệu, … theo quy định của pháp luật
            </p>
            <p className="content-text-term">
              Bạn có nghĩa vụ: tự bảo vệ dữ liệu cá nhân của mình; yêu cầu các
              tổ chức, cá nhân khác có liên quan bảo vệ dữ liệu cá nhân của
              mình; cung cấp đầy đủ, chính xác dữ liệu cá nhân; thực hiện quy
              định của pháp luật về bảo vệ dữ liệu cá nhân và các nghĩa vụ khác
              theo quy định của pháp luật.
            </p>
            <p className="content-text-term">
              Nếu Bạn có bất kỳ câu hỏi hoặc yêu cầu nào liên quan đến Chính
              Sách này, vui lòng liên hệ với bộ phận Chăm sóc Khách hàng của
              Chúng Tôi hoặc thông qua địa chỉ email sales@carlink.com.vn
            </p>
          </div>
        </div>
      </div>
      <FormRegister targetRef={targetRef} />
    </div>
  );
};
export default PrivacyPolicyes;
