import { useLocation, useNavigate } from 'react-router';
import "./newHotDetail.scss";
import {  useEffect, useState } from 'react';
import PostNewsApi from '../../Api/New';
import { useSelector } from 'react-redux';
import { getCategory } from '../../config/helper';
const NewHotDetail = ()=>{
    const navigate = useNavigate();
   
    const [listNewHot, setListNewHot] = useState([]);
    const location = useLocation();
    const listCategory = useSelector((state)=> state.menu.data);
    const id = getCategory(listCategory, location?.pathname.slice(1)) 
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
      const fetchListNewHot = async () => {
        try {  
          const response = await PostNewsApi.GetNewHot(id,{});
          setListNewHot(response.data);
        } catch (err) {
          throw err;
        }
      };
      useEffect(() => {
        fetchListNewHot();
      },[]);
    return (
        <>
        <div className="containerNewHotDetail">
        {listNewHot?.map((item) => (
              <div className="childContainerRight" 
                onClick={()=>{
                    navigate(`/tin-tuc/chi-tiet/${item?.news_id}`) ;
                    scrollToTop();   
                  }} >
                <div className="imgRightNewHot">
                  <img alt="" src={item?.news_hot_image_url} />
                </div>
                <div className="navbarContentRight">
                  <div className="titleContentRight">
                    <h2>{item?.title}</h2>
                  </div>
                  <div className="contentRightNewHot">
                    <p>{item?.description}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
        
        </>
    )
}
export default NewHotDetail;