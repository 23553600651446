import AxiosClient from "./api";
const Site = process.env.REACT_APP_SITE_ID;
const GetAll = (config) =>{
    return AxiosClient.get(`product/${Site}/lang/1`, config)
}
const GetExtraProduct = (config)=> {
    return AxiosClient.get(`getproductbycategory/7`, config)
}
const GetByIdProduct = (id,config)=>{
    return AxiosClient.get(`getproductbycategory/${id}`, config)
}

const ProductApi = {GetAll, GetExtraProduct,GetByIdProduct};

export default ProductApi