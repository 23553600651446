import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AxiosClient from '../../Api/api'; 
const Site = process.env.REACT_APP_SITE_ID;
export const getListInformation = createAsyncThunk (
  'informationData', async()=>{
    try{
       const response = await AxiosClient.get(`sitemeta/${Site}/lang/1`);
       const {data ,status} = response
       if(status === 200) {
        setData(data)
        return data
      }
      return response;
    }
    catch(error) {
        
    }
  }
)
const infoprmationSlice = createSlice({
  name: 'menu',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {
    setData: (state, action)=> {
      state.data = action?.payload?.data;
     
    },
    setError: (state, action)=> {
      state.error = action.error.message;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListInformation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getListInformation.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getListInformation.rejected, (state, action) => {
        state.loading = false;
      });
  },
  
});

export default infoprmationSlice.reducer;
export const {setData} = infoprmationSlice.actions

