import "./contactChildren.scss";
import Call from '../../../asset/images/call-outgoing.png'
import { useSelector } from "react-redux";
const ContactChild = () => {
  const listInformation = useSelector((state)=>state.information.data);
  const hotiline = listInformation && listInformation?.map(item=> item.site_hotline);
  const handleClickCall =()=>{
    const telLink = `tel:${hotiline}`;
    window.open(telLink, '_blank');
  };
  console.log("hotline",hotiline);
  return (
    <>
      <div className="wapperContact">
        <div className="wapperPhone" onClick={handleClickCall}>
          <img alt="" src={Call}/>
          <div className="wapperContent">
            <p className="contactTitle">Tổng đài hỗ trợ</p>
              <p className="contactPhone"  >{hotiline}</p>
          </div>
        </div>
      </div>
      <div className="wapperContactMobile">
        <div className="wapperPhone" onClick={handleClickCall}>
          <img alt="" className="phoneIconMobile" src={Call}/>
          <div className="wapperContent">       
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactChild;
