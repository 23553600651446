import {useEffect, useState } from "react";
import "./recruitment.scss";
import Contact from "../../Component/PageComponent/Contact/Contact";
import FormRegister from "../../Component/PageComponent/FormRegister/FormRegister";
import GoTop from "../../Component/PageComponent/GoTop/GoTop";
import Questions from "../../Component/PageComponent/Questions/Questions";
import { Button, Form, Input, Modal, Upload, message } from "antd";
import StatisticalApi from "../../Api/Statistical";
import JobApi from "../../Api/Job";
import Search from "antd/es/input/Search";
import BannerRecruitment from "../../asset/images/bannerRecuitment.png";
import {
  ArrowRightOutlined,
  RightOutlined,
  CloseOutlined,
  FileOutlined
} from "@ant-design/icons";
import numeral from "numeral";
import TextArea from "antd/es/input/TextArea";
import imgSuccess from "../../asset/images/success.png";
import imgError from "../../asset/images/error.png";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getCategory, getListMetaSeo } from "../../config/helper";
import Loading from "../../Component/PageComponent/Loading/Loading";
import { CloseLoading, openLoading } from "../../redux/Slice/loadingSlice";
const Recruitment = ({targetRef}) => {
  const dispath = useDispatch();
  const [form] = Form.useForm(); 
  const [listStatiscalRecuitment, setListStatiscalRecuitment] = useState([]);
  const [ListJob, setListJob] = useState([]);
  const [listInformationRecuitment, setListInformationRecuitment] = useState([]);
  const [titleJob, setTitleJob] = useState('');
  const [modalRecuitment, setModalRecuitment] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [isModalError, setIsModalError] = useState(false);
  const [successe, setSuccesse] = useState(false);
  const [error, setError] = useState(false);
  const [listValueSearch, setListValueSearch] = useState('');
  const [fileError, setFileError] = useState(false);
  const location = useLocation();
  const listCategory = useSelector((state)=> state.menu.data);
  const id = getCategory(listCategory, location?.pathname.slice(1));
  const listMetaSeo = getListMetaSeo(listCategory,location?.pathname.slice(1));
  const props = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload(file) {
      const maxSize = 5 * 1024 * 1024;
      const allowedExtensions = ['.doc', '.docx', '.pdf'];
      const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
    
      // Kiểm tra kích thước của tệp
      if (file.size > maxSize) {
        message.error('Kích thước tệp quá lớn, vui lòng chọn tệp nhỏ hơn 5MB!', 5);
        setFileError(true);
        return false;
      }
      // Kiểm tra loại tệp tin
      if (!allowedExtensions.includes(fileExtension)) {
        message.error('Chỉ được phép tải lên các tệp .doc, .docx hoặc .pdf!', 5);
        setFileError(true);
        return false;
      }
      setFileError(false);
      return true;
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {

      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} Tải tập tin lên thành công!`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} Tải tập tin lên thất bại!.`);
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const search =()=>{
    fetchListJobFilter(listValueSearch);  
  }
  const handelSearch = () => {
    fetchListJobFilter(listValueSearch);   
  };
  const valueSearch = (value) => {
    setListValueSearch(value);
    if (value === "") {
      fetchListJob(); 
    }
  };
  const hiddenModal =()=>{
    setIsModalSuccess(false);
    setIsModalError(false);
  }
  const fetchStatiscalRecuitment = async () => {
    try {
     
      const response = await StatisticalApi.GetStacticRecuitment(id,{});
      setListStatiscalRecuitment(response.data.data);
    } catch (err) {
      throw err;
    }
  };
  const fetchInformationRecuitment = async (config = {}) => {
    try {
      const response = await JobApi.GetListInformationJob(config);
      setListInformationRecuitment(response.data);
    } catch (err) {
      throw err;
    }
  };
  const fetchListJob = async () => {
    try {
      const response = await JobApi.GetListJob(id);
      setListJob(response.data);
    } catch (err) {
      throw err;
    }
  };
  const fetchListJobFilter = async (listValueSearch) => {
    try {
      const response = await JobApi.GetListJobFilter(id,listValueSearch);
      setListJob(response.data);
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    fetchListJob();
  }, []);
  useEffect(() => {
    fetchStatiscalRecuitment();
  }, [id]);

  useEffect(() => {
    fetchInformationRecuitment();
  }, []);
  const handleRecuitment = ( job_description) => {
    setModalRecuitment(true);
    setTitleJob(job_description);
  };
  const handleClose = () => {
    setModalRecuitment(false);
    form.resetFields();
  };
  const onFinishFailed = () => {
  };
  const onFinish = async (values) => {
      try{
         
          const formData = new FormData();
          formData.append('name', values.name);
          formData.append('phone', values.phone);
          formData.append('email', values.email);
          formData.append('address', values.address);
          formData.append('job_id', 1); 
          formData.append('file', values.file.file.originFileObj);
          dispath(openLoading());
          const response = await JobApi.CreateRegisterCv(formData);
           if(response.status === 200){
                 dispath(CloseLoading());
                 setSuccesse(true);
                 setSuccesse(response.data.message);
                 setIsModalSuccess(true);
                 form.resetFields();
           }if(response.status === 429){
                dispath(CloseLoading());
                setSuccesse(true);
                setSuccesse(response.data.message);
                setIsModalSuccess(true);
           }
           else{
              dispath(CloseLoading());
              setError(true);
              setError(response.data.message);
           }
      }catch(err){
          dispath(CloseLoading());
          setIsModalError('Lỗi đăng kí xin vui lòng thử lại!')
          throw err;
      }
  };
  return (
    <>
    {listMetaSeo &&(

    <Helmet>
    <title>{listMetaSeo?.category_meta_title}</title>
      <meta name="description" content={listMetaSeo?.category_meta_description}/>
      <meta name="keywords" content={listMetaSeo?.category_meta_keywords}/>
      
    </Helmet>
    )}
      <div>
        {listInformationRecuitment.map((item) => {
          return (
            <div
              className="informationRecuitment informationRecuitmentMobile"
            >
              <div
                className="titleRecuitment titleRecuitmentMobile"
              >
                <h1>{item.category_title}</h1>
              </div>
              <div
                className="descriptionRecuitment descriptionRecuitmentMobile"
              >
                <p>{item.category_description}</p>
              </div>
              <div
                className="bannerRecuitment bannerRecuitmentMobile"
              >
                <img alt="" src={item.category_banner_url} />
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="titleJobHeader titleJobHeaderMobile"
      >
        <div
          className="titleTextHeader titleTextHeaderMobile"
        >
          <h1>Vị trí đang tuyển nổi bật</h1>
        </div>
        <div className="searchJob searchJobMobile">
          <Search
            placeholder="Nhập vị trí bạn muốn ứng tuyển"
            allowClear
            onChange={(e) => valueSearch(e.target.value)}
            onSearch={handelSearch}
            onPressEnter={search}
            style={{            
            }}
          />
        </div>
      </div>
      <div className="containerJob containerJobMobile">
        {ListJob.map((item) => {
          return (
            <div className="navbarJob navbarJobMobile">
              <div
                className="titleLocationJob titleLocationJob"
              >
                <p>{item.job_position}</p>
              </div>
              <div className="titleJob titleJobMobile">
                <h2>{item.job_description}</h2>
              </div>
              <div
                className="addressJob addressJobMobile"
              >
                <p>
                  Khu vực |{" "}
                  <span
                    className="TextLeft TextLeftMobile"
                  >
                    {item.job_region}
                  </span>
                </p>
              </div>
              <div className="income incomeMobile">
                <p>
                  {numeral(item.salary_from).format("0,000,00")}đ -{" "}
                  {numeral(item.salary_to).format("0,000,00")}đ
                </p>
              </div>
              <div
                className="buttonRecruitment buttonRecruitmentMobile"
                onClick={()=>{
                  handleRecuitment( item.job_description);
                 
                }}
              >
                <button className="buttonCv">
                  <ArrowRightOutlined />
                  <span>
                     Ứng tuyển ngay
                  </span>
                  
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <div className="detailJob detailJobMobile">
        <div
          className="navbarTextDetail navbarTextDetailMobile"
        >
          <p className="textDetail textDetailMobile">
            Khám phá thêm nhiều vị trí tuyển dụng từ chúng tôi.
          </p>
          <div
            className="containerSeeMore containerSeeMoreMobile"
          >
            <p className="seeMore seeMoreMobile">
              Xem thêm
            </p>
            <RightOutlined />
          </div>
        </div>
      </div>
      <div
        className="bannerRecuitmentCategory bannerRecuitmentCategoryMobile"
      >
        <div className="wapperContentBannerRercuit">
          <div
            className="headerContentBannerRercuit headerContentBannerRercuitMobile"
          >
            <h1>Nơi bạn thuộc về</h1>
            <p>
              Hãy tham gia một trong các đội nhóm của chúng tôi, có thể là công
              nghệ, phát triển sản phẩm hoặc chiến lược kinh doanh. Mỗi nhóm đều
              được thống nhất và có chuyên môn, lĩnh vực làm việc riêng, hãy tìm
              hiểu và chọn ra vị trí thuộc về bản thân bạn.
            </p>
          </div>
          <div className="navbarImgaeBannerRecuitCategory">
            <div
              className="imageBannerRercuitment imageBannerRercuitmentMobile"
            >
              <img alt="" src={BannerRecruitment} />
            </div>
            <div
              className="wapperChildrenContentBanner wapperChildrenContentBannerMobile"
            >
              <div
                className="titleChildrenBanner titleChildrenBannerMobile"
              >
                <h2>Tham gia đội ngũ công nghệ của chúng tôi</h2>
                <p>
                  Với nhân lực có trình độ cao và mục tiêu mà công ty đang hướng
                  đến, đội ngũ công nghệ đang hoạt động tại các lĩnh vực như
                </p>
              </div>
              <div
                className="ContatinerchildrenContentBanner ContatinerchildrenContentBannerMobile"
              >
                <div
                  className="NavbarChildrenContent NavbarChildrenContentMobile"
                >
                  <ArrowRightOutlined className="iconBanner" />
                  <p>Phát triển phần mềm</p>
                </div>
                <div
                  className="NavbarChildrenContent NavbarChildrenContentMobile"
                >
                  <ArrowRightOutlined className="iconBanner" />
                  <p>Thiết kế trải nghiệm người dùng</p>
                </div>
                <div
                  className="NavbarChildrenContent NavbarChildrenContentMobile"
                >
                  <ArrowRightOutlined className="iconBanner"/>
                  <p>Kinh doanh & truyền thông</p>
                </div>
                <div
                  className="childrenButton childrenButtonMoblie" 
                >
                  <button>Xem việc làm</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      { listStatiscalRecuitment?.length && listStatiscalRecuitment?.map((item) => {
        return (
          <div className="wapperTitleStatiscalM">
            <div
              className="headerTitleStatiscal headerTitleStatiscalMobile"
            >
              <h1>{item?.title}</h1>
            </div>
            <div className="containerStatistical">
              <div
                className="navbarStatical1 navbarStatical1Mobile"
              >
                <div className="imgaeStatiscal">
                  <img alt="" src={item?.statistics_image_url1} />
                </div>
                <div className="titleStatiscal">
                  <h2>{item?.statistics_name1}</h2>
                </div>
                <div className="contentTextStatiscal">
                  <p>{item?.statistics_description1}</p>
                </div>
              </div>
              <div
                className="navbarStatical2 navbarStatical2Mobile"
              >
                <div className="imgaeStatiscal">
                  <img alt="" src={item?.statistics_image_url2} />
                </div>
                <div className="titleStatiscal">
                  <h2>{item?.statistics_name2}</h2>
                </div>
                <div className="contentTextStatiscal">
                  <p>{item?.statistics_description2}</p>
                </div>
              </div>
              <div
                className="navbarStatical3 navbarStatical3Mobile"
              >
                <div className="imgaeStatiscal">
                  <img alt="" src={item?.statistics_image_url3} />
                </div>
                <div className="titleStatiscal">
                  <h2>{item?.statistics_name3}</h2>
                </div>
                <div className="contentTextStatiscal">
                  <p>{item?.statistics_description3}</p>
                </div>
              </div>
              <div
                className="navbarStatical4 navbarStatical4Mobile"
              >
                <div className="imgaeStatiscal">
                  <img alt="" src={item?.statistics_image_url4} />
                </div>
                <div className="titleStatiscal">
                  <h2>{item?.statistics_name4}</h2>
                </div>
                <div className="contentTextStatiscal">
                  <p>{item?.statistics_description4}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="containerModalCv">
      <Modal  
        width={720}   
        title={
          <div className="headerModal headerModalMobile">
            <p>Bạn đang ứng tuyển vị trí <span className="titleModaljob">{titleJob}</span>  tại CarLink JSC</p>
            <div className="buton-close-modal-cv">
              <CloseOutlined onClick={handleClose} />
            </div>
          </div>
        }
        open={modalRecuitment}
        onOk={handleClose}
        onCancel={handleClose}
        style={{ with: "720px !importaion" }}
        className="containerModalRecruitment"
      >
        <Form 
           onFinish={onFinish}
           onFinishFailed={onFinishFailed}
           form={form}
        >
          <div className="containerModalRecuitment">
            <div className="NavbarModalRecuitment">
              <div className="modalInputLefft">
                <p className="titleInputCv"> 
                  Họ và tên 
                  <sup className="subText">*</sup>
                </p>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập họ và tên!",
                    },
                  ]}
                >
                  <Input placeholder="Nhập họ và tên"/>
                </Form.Item>
              </div>
              <div className="modalInputRight">
                <p className="titleInputCv">
                  Số điện thoại
                  <sup className="subText">*</sup>
                </p>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập số điện thoại!",
                    },
                  ]}
                >
                  <Input placeholder="Nhập số điện thoại" />
                </Form.Item>
              </div>
            </div>
            <div className="NavbarModalRecuitment">
              <div className="modalInputLefft">
                <p className="titleInputCv">
                  Email
                  <sup className="subText">*</sup>
                </p>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập email!",
                    },
                  ]}
                >
                  <Input placeholder="Nhập Email" />
                </Form.Item>
              </div>
              <div className="modalInputRight">
                <p className="titleInputCv">Địa chỉ</p>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập địa chỉ!",
                    },
                  ]}
                >
                  <Input placeholder="Nhập địa chỉ chỗ ở hiện tại" />
                </Form.Item>
              </div>
            </div>
            <div>
              <p className="titleInputCv">
                CV của bạn
                <sup className="subText">*</sup>
              </p>
               <div className="containerUploadCv containerUploadCvMobile">
                <Form.Item 
                  name="file"                                   
                  rules={[{ required: true, message: 'Vui lòng chọn tệp!' }]}       
                  validateStatus={fileError ? 'error' : ''} // Thiết lập trạng thái lỗi của Form.Item
                  help={fileError ? 'File không hợp lệ!' : null}         
                >
                <Upload
                    {...props}
                  // onChange={handleChange}
                  maxCount={1}            
                >
                  <Button className="buttonUploadFi" icon={<FileOutlined />} >Chọn tệp</Button>
                </Upload>
              </Form.Item>
              <div className="TextRequiredUpload TextRequiredUploadMobile">
                 <p>Hỗ trợ định dạng .doc, .docx, .pdf và kích cỡ file nhỏ 5MB</p>
              </div>
               </div>
            </div>
            <div>
              <label className="titleInputCv">Lời nhắn</label>
              <Form.Item name="message">
                <TextArea row={4} placeholder="Nhập lời nhắn bạn muốn để lại" />
              </Form.Item>
            </div>
            <div>            
            </div>
          </div>
        <div className="containerBtnRecruit containerBtnRecruitMobile">
          <div className="btnCancel btnCancelMobile">
            <button type="button" onClick={handleClose}>Huỷ</button>
          </div>
          <div className="btnOk btnOkMobile">
            <button type="submit">Nộp CV</button>
          </div>
        </div>
        </Form>
      </Modal>
      </div>
      <div>
        <Modal open={isModalSuccess} width={520} className="modalNotification modalNotificationMobile" >
        <img alt="" className="imageSuccses imageSuccsesMobile" src={imgSuccess} />
           <h3>Nộp hồ sơ thành công</h3>
          {imgSuccess && <p>{successe} </p>}
          <button onClick={hiddenModal} className="buttonConfirm buttonConfirmMobile">
            Đã Hiểu
          </button>
        </Modal>
        <Modal open={isModalError} width={520} className="modalNotification modalNotificationMobile">
        <img alt="" className="imageSuccses imageSuccsesMobile" src={imgError} />
           <h3>Nộp hồ sơ thất bại</h3>
          {imgError && <p>{error} </p>}
          <button onClick={hiddenModal} className="buttonConfirm buttonConfirmMobile">
            Đã Hiểu
          </button>
        </Modal>
      </div>
      <Questions />
      <FormRegister targetRef={targetRef} />
       <Loading/>
      
      <GoTop />
      <Contact />
    </>
  );
};
export default Recruitment;
