import AxiosClient from "./api";
const GetAll = ( config)=>{
    return AxiosClient.get(`statistics/2`, config)
}
const GetStacticRecuitment = (id, config)=>{
  return AxiosClient.get(`statistics/${id}`, config)
}

const StatisticalApi = {GetAll,GetStacticRecuitment};

export default StatisticalApi;