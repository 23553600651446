import "./ThemesBus.scss";
import { Pagination } from "antd";
import FormRegister from "../../Component/PageComponent/FormRegister/FormRegister";
import {useLocation, useNavigate } from "react-router";
import backgroundFooter from "../../asset/images/backgroundFooter.png";
import RegisterThemse from "../../Component/PageComponent/RegisterThemse/RegisterThemse";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../redux/Slice/modalSlice";
import { useEffect, useState } from "react";
import ThemseApi from "../../Api/Themse";
import ContactChild from "../../Component/PageComponent/Contact/Contact";
import GoTop from "../../Component/PageComponent/GoTop/GoTop";
import { getListMetaSeo } from "../../config/helper";
import { Helmet } from "react-helmet";
const ThemseBus = ({ targetRef }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const listCategory = useSelector((state) => state.menu.data);
  const listMetaSeo = getListMetaSeo(listCategory, location?.pathname.slice(1));
  const handleOpenModal = () => {
    dispatch(openModal());
  };
  const handldeClickId =(id, name)=>{
       const themseId = {
         id:id,
         name:name
       }
      sessionStorage.setItem("idThemse", JSON.stringify( themseId));
  }
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [listThemse, setListThemse] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRows, setTotalRow] = useState();
  const [pageSize, setPageSize] = useState(0);
  const [page, setPage] = useState(0);
  const fetchListThemse = async (current) => {
    try {
      const response = await ThemseApi.GetAll(current);
      setListThemse(response?.data?.data?.data);
      setTotalRow(response?.data?.data?.total);
      setCurrentPage(response?.data?.data?.current_page);
      setPageSize(response?.data?.data?.per_page);
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    fetchListThemse(page);
  }, [page]);
  const handleOnchangePage = (current) => {
    setPage(current);
  };
  return (
    <>
      {listMetaSeo && (
        <Helmet>
          <title>{listMetaSeo?.category_meta_title}</title>
          <meta
            name="description"
            content={listMetaSeo?.category_meta_description}
          />
          <meta name="keywords" content={listMetaSeo?.category_meta_keywords} />
        </Helmet>
      )}
      <div className="wapper-themseBus">
        <div className="themse-header">
          <div>
            <h1>Khám phá kho giao diện nhà xe</h1>
          </div>
          <div className="them-description">
            <h3>
              Với hàng trăm mẫu giao diện web được nghiên cứu tối ưu danh riêng
              cho thị trường vận tải hành khách tại Việt Nam, đảm bảo phù hợp và
              đáp ứng đầy đủ những yêu cầu của bạn.
            </h3>
          </div>
        </div>
        <div className="themse-wapper">
          {listThemse?.length &&
            listThemse?.map((item) => {
              return (
                <div className="themse-container">
                  <div className="them-img">
                    <img src={item?.home_desktop_image_url} alt="" />
                    <div className="them-content">
                      <div className="them-context">
                        <div className="them-content-title">
                          <h2>{item?.name}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="themse-button-wapper">
                    <button
                      className="button1"
                      onClick={() => {
                        navigate(`xem-chi-tiet/${item?.id}`);
                        
                        scrollToTop();
                      }}
                    >
                      Xem thực tế
                    </button>
                    <button
                      onClick={() => {
                        handleOpenModal();
                        handldeClickId(item?.id ,item?.name);
                      }}
                      className="button2"
                    >
                      Chọn mẫu
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="postNewPaingThemse postNewPaingThemseMobile">
          <Pagination
            defaultCurrent={1}
            total={totalRows}
            current={currentPage}
            pageSize={pageSize}
            onChange={handleOnchangePage}
          />
        </div>
        <div className="backgroundFooter-them-bus">
          <img src={backgroundFooter} alt="" />
        </div>
      </div>
      <RegisterThemse />
      <FormRegister targetRef={targetRef} />
      <ContactChild />
      <GoTop />
    </>
  );
};
export default ThemseBus;
