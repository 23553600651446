import React from "react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import  { store, persistor } from "./redux/store";
import App from "./App";
const root = createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
     <PersistGate loading={null} persistor={persistor}>
      <Provider store={store}>
        <App />
      </Provider>
      </PersistGate>
  </BrowserRouter>
  // </React.StrictMode>
);
reportWebVitals();
