import AxiosClient from "./api";
const Site = process.env.REACT_APP_SITE_ID;
const GetAll = (config) =>{
    return AxiosClient.get(`listhomepagebanners/${Site}/lang/1`, config)
}
const GetListBannerCategory = ( config)=>{
    return AxiosClient.get(`listbannerbycatagory/3`, config)
}
const Create = (data, config) => {
    return AxiosClient.post("banner/1", data, config);
  };
  const Update = (id, data, config) => {
    return AxiosClient.put(`banner/1/${id}`, data, config);
  };
  const Delete = (id, config) => {
    return AxiosClient.delete(`banner/1${id}`, config);
  };
const BannerApi = {GetAll,GetListBannerCategory,Create,Update,Delete};

export default BannerApi