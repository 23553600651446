import { useSelector } from "react-redux";
import "./loading.scss";
import { Flex, Spin } from "antd";
const Loading = () => {
  const isOpen = useSelector(state => state.loadding.isOpen)
  return (
    <>
{isOpen &&(
  <div className="overlay" >
    <div className="wapper-loading">
      <Flex gap="small" vertical>
        <Flex gap="small">
          <Spin tip="" size="large">
            <div className="content" />
            
          </Spin>
            <div className="title-loading">
               <p>ĐANG TẢI ...</p>
            </div>
        </Flex>
      </Flex>
    </div>
    </div>
)} 
    </>
  );
};
export default Loading;
