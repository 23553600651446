import { useCallback, useEffect, useState } from "react";
import FormRegister from "../../Component/PageComponent/FormRegister/FormRegister";
import "./ThemseDetail.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  UpOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import backgroundFooter from "../../asset/images/backgroundFooter.png";
import RegisterThemse from "../../Component/PageComponent/RegisterThemse/RegisterThemse";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../redux/Slice/modalSlice";
import ThemseApi from "../../Api/Themse";
import { useParams } from "react-router";
import ContactChild from "../../Component/PageComponent/Contact/Contact";
import GoTop from "../../Component/PageComponent/GoTop/GoTop";
import { getListThemseBus } from "../../config/helper";
const CustomPrevArrow = ({ onClick }) => (
  <div className="custom-arrow prev" onClick={onClick}>
    <UpOutlined />
  </div>
);
const CustomNextArrow = ({ onClick }) => (
  <div className="custom-arrow next" onClick={onClick}>
    <DownOutlined />
  </div>
);
const CustomPrevArrowMobile = ({ onClick }) => (
  <div className="custom-arrow prev" onClick={onClick}>
    <LeftOutlined />
  </div>
);
const CustomNextArrowMobile = ({ onClick }) => (
  <div className="custom-arrow next" onClick={onClick}>
    <RightOutlined />
  </div>
);
const ThemseDetail = () => {
  const { id } = useParams();
  const idThem = id;
  const listThemseBus = useSelector((state)=> state?.themseBus?.data?.data);
  const themseDetail =  getListThemseBus(listThemseBus , idThem) 
  const [listThemseDetail, setListThemseDetail] = useState([]);
  const [selectImg, setSelectImg] = useState(
    listThemseDetail[0]?.page_desktop_image_url ?? null
  );
  const [selecName, setSelectName] = useState(
    listThemseDetail[0]?.page_name ?? null
  );
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const settingsDesktop  = {
    infinite: false,
    vertical: true,
    verticalSwiping: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };
  const settingsMobile  = {
    infinite: true,
    vertical: true,
    verticalSwiping: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrowMobile />,
    nextArrow: <CustomNextArrowMobile />,
  };
  const fetchThemseDetail = useCallback( async () => {
   if(idThem){
     try {
      const response = await ThemseApi.GetById(idThem);
      setListThemseDetail(response.data);
    } catch (error) {
      throw error;
    }
   }
  },[idThem]);
  useEffect(() => {
    fetchThemseDetail();
  }, [fetchThemseDetail]);
  const handleImageClick = (imageUrl, pageName) => {
    setSelectImg(imageUrl);
    setSelectName(pageName);
  };
  const handleOpenModal = () => {
    dispatch(openModal());
  };

  useEffect(() => {
    setSelectImg(listThemseDetail[0]?.page_desktop_image_url);
    setSelectName(listThemseDetail[0]?.page_name);
  }, [
    listThemseDetail[0]?.page_desktop_image_url,
    listThemseDetail[0]?.page_name,
  ]);

  return (
    <>
      <div className="waper-them-detail">
        <div className="header-title-themDetail">
          <div className="header-title-Text">
            <p>
              Mẫu Web nhà xe /<span> Chi tiết mẫu web</span>
            </p>
          </div>
         
         {themseDetail &&(
    
       <div className="header-content-text ">
            <h1> { themseDetail?.themseName} </h1>
            <span>/</span>
            {selecName ? (
              <h2 className="name-sample-themse">({selecName})</h2>
            ) : (
              <p>không có dữ liệu</p>
            )}
          </div>
         )} 
        </div>
        <div className="container-them-detail">
          <div className="container-them-left">
            {selectImg ? (
              <img
                src={selectImg}
                alt="Selected Image"
                style={{
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            ) : (
              <p>Chưa có ảnh được chọn</p>
            )}
          </div>
          <div className="container-them-right">
            <Slider {...(isMobile ? settingsMobile : settingsDesktop)}>
              { listThemseDetail?.length && listThemseDetail?.map((image) => (
                <div
                  className="imgSlideThem"
                  key={image.id}
                  onClick={() =>
                    handleImageClick(
                      image.page_desktop_image_url,
                      image.page_name
                    )
                  }
                >
                  <img
                    src={image.page_desktop_image_url}
                    alt={`Image ${image.id}`}
                    style={{
                      width: "95%",
                      height: "300px",
                      cursor: "pointer",
                      objectFit: "cover",
                      objectPosition: "center",
                      transition: "border-color 0.3s",
                      border:
                        selectImg === image.page_desktop_image_url
                          ? "2px solid red"
                          : "2px solid orange",
                    }}
                  />
                </div>
              ))}
            </Slider>
          </div>
          
             
        </div>
        <div className="content-footer-them">
          <div className="context-footer-them">
            <p>
              Cảm thấy ưng ý với mẫu website này? Hãy đăng ký ngay với chúng tôi
            </p>
          </div>
          <div className="button-them-detail">
            <button onClick={handleOpenModal}> Đăng ký mẫu</button>
          </div>
        </div>
        <div className="backgroundFooter-them">
          <img src={backgroundFooter} alt="" />
        </div>
      </div>
      <RegisterThemse />
      <ContactChild/>
      <GoTop/>
      <FormRegister />
    </>
  );
};
export default ThemseDetail;
