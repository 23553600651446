import FormRegister from "../../Component/PageComponent/FormRegister/FormRegister";
import "./termsCondition.scss";
import { Helmet } from "react-helmet";
const TermsConditions = ({ targetRef }) => {
  return (
    <>
      <Helmet>
        <title>Điều khoản và điều kiện</title>
        <meta name="description" content="Giới thiệu điều khoản và điều kiện" />
        {/* <meta name="keywords" content={listInformation[0]?.site_keywords} /> */}
        <meta property="og:locale" content="" />
        <meta property="og:type" content="" />
        <meta property="og:description" content="" />
        <meta property="og:url" content="" />
        <meta property="og:site_name" content="" />
        <meta property="og:img" content="" />
      </Helmet>
      <div className="wapper-termsCondition">
        <div className="header-">
          <h1
            style={{
              fontSize: "24px",
              fontFamily: "LexendRegular",
              color: "#333",
            }}
          >
            {" "}
            GIỚI THIỆU ĐIỀU KHOẢN VÀ ĐIỀU KIỆN
          </h1>
        </div>
        <div>
          <p
            style={{
              fontSize: "14px",
              fontFamily: "LexendRegular",
              color: "#666",
            }}
          >
            Công ty CỔ PHẦN CARLINK VIỆT NAM ( “Chúng tôi”) thực hiện việc cung
            cấp Dịch Vụ thông qua website phần mềm app.carlink.com.vn (“Phần Mềm
            Website”). Bằng cách cài đặt, đăng nhập, truy cập và sử dụng Phần
            Mềm Website, người sử dụng Dịch Vụ (“Bạn” hoặc “Người Dùng”) đồng ý
            với các Điều Khoản và Điều Kiện này, các chính sách liên quan tới
            Sản Phẩm của Công ty và các chính sách khác được thông báo trên Phần
            Mềm Website tại từng thời điểm. Chúng Tôi có thể sửa đổi, bổ sung,
            loại bỏ một số nội dung của Điều Khoản Và Điều Kiện này vào bất kỳ
            thời điểm nào mà không cần thông báo trước. Bằng việc tiếp tục đăng
            nhập, truy cập và sử dụng Phần Mềm Website, sử dụng Dịch Vụ của
            Chúng Tôi, Bạn đồng ý và chấp thuận với những nội dung thay đổi đó.
            Nếu Bạn sử dụng Dịch Vụ trên Phần Mềm Website để đặt Sản Phẩm cho cá
            nhân/ tổ chức khác mà Bạn được ủy quyền hợp pháp để thực hiện yêu
            cầu (“Người Tiêu Dùng Cuối Cùng”), Bạn có nghĩa vụ thông báo các
            Điều khoản và Điều Kiện này cho Người Tiêu Dùng Cuối Cùng.
          </p>
          <h2 className="text-item">I. PHẠM VI DỊCH VỤ</h2>
          <p className="content-text-term">
            Thông qua Phần Mềm Website, Chúng Tôi cung cấp công cụ để nhà xe dễ
            dàng quản lý hệ thống xe chở khách, bán vé, lưu trữ thông tin khách
            hàng. Hệ thống báo cáo đẩy đủ chức năng, xuất dữ liệu đầy đủ phục vụ
            nhu cầu quản lý của nhà xe. Các chức năng điều hành xe, tuyến xe,
            lịch chạy, màn chờ đặt vé đều được chúng tôi thiết kế tỉ mỉ và lập
            trình trơn tru. Ngoài ra chúng tôi còn cho phép nhà xe tự tuyển dụng
            đại lý của chính mình để nâng cao doanh thu. Chúng tôi tạo tài khoản
            cho nhà xe có thể dễ dàng quản lý đại lý, đại lý cũng sẽ thao tác
            bán vé trên Phần Mềm Website của chúng tôi. Chúng tôi cung cấp công
            cụ đủ chức năng cho đại lý có thể dễ dàng đặt vé, xuất báo cáo doanh
            thu hoa hồng.
          </p>
          <h2 className="text-item">II. GIÁ, THUẾ, PHÍ VÀ THANH TOÁN</h2>
          <h3> 1. Giá, thuế, phí</h3>
          <p className="content-text-term">
            Giá niêm yết trên Phần Mềm Website là do các nhà xe tự cài đặt.
            Chúng tôi sẽ không can thiệp về vấn đề giá vé này.
          </p>
          <h3> 2. Thanh toán</h3>
          <p className="content-text-term">
            Nhà xe sẽ thanh toán chi phí sử dụng Phần mềm website theo đúng hợp
            đồng đã ký kết giữa Chúng tôi và nhà xe.
          </p>
          <h2 className="text-item">III. TRÁCH NHIỆM CỦA NGƯỜI DÙNG</h2>
          <div style={{ paddingLeft: "12px" }}>
            <p className="content-text-term">
              <span style={{ color: "black", fontWeight: "bold" }}> 1 </span>.
              Bạn phải đủ 18 tuổi trở lên và có đầy đủ năng lực hành vi dân sự
              khi cài đặt và sử dụng Phần mềm website của Chúng tôi và phải tuân
              thủ các quy định tại Điều Khoản và Điều Kiện này.
            </p>
            <p className="content-text-term">
              <span style={{ color: "black", fontWeight: "bold" }}> 2 </span>.
              Để đảm bảo tính công bằng cho tất cả Người Dùng, Chúng Tôi sẽ từ
              chối các yêu cầu cài đặt thông tin trên hệ thống Phần mểm website
              nhằm mục đích sử dụng cho cá nhân, thông tin sai lệch ảnh hưởng
              đến người mua hàng.
            </p>
            <p className="content-text-term">
              <span style={{ color: "black", fontWeight: "bold" }}> 3</span>.
              Tất cả thông tin, dữ liệu cá nhân được Bạn cung cấp trên Phần mểm
              website này đều là sự thật, chính xác, hiện hành và đầy đủ. Nếu có
              bất kỳ thông tin nào gian dối, Bạn sẽ phải tự mình chịu trách
              nhiệm và bồi thường toàn bộ các thiệt hại phát sinh.
            </p>
            <p className="content-text-term">
              {" "}
              <span style={{ color: "black", fontWeight: "bold" }}>4</span>. Về
              việc quản lý và sử dụng tài khoản trên c
            </p>
            <div style={{ paddingLeft: "8px" }}>
              <p className="content-text-term">
                <span style={{ color: "black", fontWeight: "bold" }}> a</span>.
                Bạn không thể chuyển giao hoặc chuyển nhượng tài khoản của mình
                cho bất kỳ bên thứ ba nào khác. Bạn phải đảm bảo tính bảo mật
                của tài khoản và bất kỳ thông tin nhận dạng nào mà Chúng Tôi
                cung cấp cho Bạn. Nếu Bạn không còn quyền kiểm soát tài khoản
                của mình, Bạn phải thông báo ngay cho Chúng Tôi để Chúng Tôi có
                thể tạm thời chặn và/ hoặc vô hiệu hóa tài khoản của Bạn đúng
                cách.
              </p>
              <p className="content-text-term">
                <span style={{ color: "black", fontWeight: "bold" }}> b</span>.
                Bạn chịu trách nhiệm cho việc sử dụng tài khoản của mình. Trong
                trường hợp xảy ra việc sử dụng trái phép tài khoản hoặc danh
                tính của Bạn do mật khẩu của Bạn bị tiết lộ. Chúng Tôi sẽ không
                chịu trách nhiệm cho bất kỳ tổn thất hoặc thiệt hại nào phát
                sinh từ việc sử dụng sai tài khoản của Bạn. Bạn có thể liên hệ
                với bộ phận Chăm sóc Khách Hàng của Chúng Tôi để nhận được sự hỗ
                trợ kịp thời trong trường hợp Bạn phát hiện có người sử dụng tài
                khoản của mình để đặt Sản Phẩm trên Phần mểm website.
              </p>
              <p className="content-text-term">
                {" "}
                <span style={{ color: "black", fontWeight: "bold" }}>c</span> .
                Chúng Tôi có quyền tạm thời chặn, xóa hoặc hủy kích hoạt tài
                khoản Bạn theo quyết định của Chúng Tôi vì bất kỳ lý do gì mà
                không cần thông báo trước. Các lý do để chặn, xóa hoặc hủy kích
                hoạt tài khoản Người Dùng có thể bao gồm nhưng không giới hạn ở:
                (i) vi phạm quy định tại Điều Khoản và Điều Kiện này; (ii) gian
                lận hoặc trộm cắp hoặc có dấu hiệu nghi ngờ gian lận, trộm cắp;
                (iii) thông tin không chính xác, sai lệch …
              </p>
            </div>
            <p className="content-text-term">
              <span style={{ color: "black", fontWeight: "bold" }}> 5</span>.
              Bạn đồng ý sẽ không thực hiện các hành động dưới đây trong quá
              trình sử dụng Phần mểm website:
            </p>
            <div style={{ paddingLeft: "8px" }}>
              <p className="content-text-term">
                • Truy cập, theo dõi hoặc sao chép bất kỳ nội dung nào trên Phần
                mểm website vì bất cứ mục đích gì mà không có sự cho phép bằng
                văn bản của Chúng Tôi;
              </p>
              <p className="content-text-term">
                • Truyền hoặc cung cấp các liên kết đến hoặc gửi một thông báo
                có chứa tài liệu có thể được coi là, theo quyết định của chúng
                tôi, có hại, không đứng đắn, bạo lực, phân biệt chủng tộc, hoặc
                phân biệt đối xử, lăng mạ, đe dọa, vi phạm quyền sở hữu trí tuệ
                hoặc tài liệu không phù hợp khác;
              </p>
              <p className="content-text-term">
                • Sử dụng Phần mểm website theo bất cứ cách nào có thể gây tổn
                hại, cản trở, phá rối h• Truyền hoặc cung cấp các liên kết đến
                hoặc gửi một thông báo có chứa tài liệu có thể được coi là, theo
                quyết định của chúng tôi, có hại, không đứng đắn, bạo lực, phân
                biệt chủng tộc, hoặc phân biệt đối xử, lăng mạ, đe dọa, vi phạm
                quyền sở hữu trí tuệ hoặc tài liệu không phù hợp khác; oặc can
                thiệp đến việc sử dụng Nền Tảng hoặc máy tính/ thiết bị di động
                của Người Dùng, hoặc gây thiệt hại, gián đoạn hoặc hạn chế các
                chức năng của phần mềm, phần cứng hoặc thiết bị viễn thông;
              </p>
              <p className="content-text-term">
                • Giành quyền truy cập trái phép hoặc thực hiện các thay đổi
                trái phép đối với Phần mểm website hoặc trang web khác có liên
                quan, các tài khoản, các hệ thống máy tính, mạng lưới kết nối
                với Phần mểm website thông qua hành động thâm nhập vào phần cứng
                máy tính, phần mềm máy tính hoặc mạng máy tính để thay đổi
                (hacking) hệ thống đó, trộm cắp mật khẩu hoặc các vấn đề tương
                tự khác;
              </p>
              <p className="content-text-term">
                • Lấy hoặc cố gắng lấy bất cứ tài liệu hoặc thông tin nào bằng
                các cách thức mà không được Nền Tảng cung cấp có chủ ý bao gồm
                nhưng không giới hạn ở việc lấy hoặc thu thập thông tin, dữ liệu
                cá nhân;
              </p>
              <p className="content-text-term">
                • Bất kỳ hành động nào khác có thể gây tác động bất lợi hoặc gây
                thiệt hại cho Nền Tảng, cho chúng tôi hoặc bên thứ ba có liên
                quan đến chúng tôi.
              </p>
            </div>

            <p className="content-text-term">
              <span style={{ color: "black", fontWeight: "bold" }}> 6 </span>.
              Bằng việc sử dụng Phần mểm website của Chúng Tôi và cung cấp cho
              Chúng Tôi thông tin, dữ liệu cá nhân của Bạn, Bạn đồng ý cho phép
              Chúng Tôi được thu thập và xử lý các dữ liệu cá nhân của Bạn bao
              gồm nhưng không giới hạn ở: thông tin cá nhân (họ tên, ngày tháng
              năm sinh, giới tính, số điện thoại, email, địa chỉ…), lịch sử truy
              cập và sử dụng Phần mểm website. Chúng Tôi cũng có thể sử dụng các
              thông tin này để cung cấp cho Bạn các gợi ý về những sản phẩm hoặc
              dịch vụ khác của Chúng Tôi. Vui lòng tham khảo Chính Sách Bảo Mật
              Thông Tin để biết cách Chúng Tôi thu thập và xử lý dữ liệu cá nhân
              của Bạn. Chúng Tôi sẽ được miễn trừ trách nhiệm khỏi tất cả các
              yêu cầu, khiếu nại, khiếu kiện liên quan tới việc xử lý dữ liệu cá
              nhân như đã nêu tại điểm này.
            </p>
          </div>
          <h2 className="text-item">IV. GIỚI HẠN TRÁCH NHIỆM CỦA CHÚNG TÔI</h2>

          <p className="content-text-term">
            Trong giới hạn tối đa được cho phép bởi luật pháp, Chúng Tôi tuyên
            bố:
          </p>
          <div style={{ paddingLeft: "12px" }}>
            <p className="content-text-term">
              <span style={{ color: "black", fontWeight: "bold" }}> 1</span>.
              Các Dịch Vụ và nội dung được hiển thị trên Phần mềm Website trên
              cơ sở “nguyên trạng” và “như sẵn có”.{" "}
            </p>
            <p className="content-text-term">
              <span style={{ color: "black", fontWeight: "bold" }}> 2 </span>.
              Chúng Tôi không đảm bảo rằng: những chức năng, tính năng cung cấp
              tại Phần mềm Website sẽ không bị lỗi hoặc gián đoạn; các trang web
              hoặc máy chủ cung cấp Dịch Vụ không bị nhiễm virus, phần mềm độc
              hại.
            </p>
            <p className="content-text-term">
              <span style={{ color: "black", fontWeight: "bold" }}> 3</span>.
              Chúng Tôi không đảm bảo rằng bất kỳ tài liệu nào Bạn tải xuống
              hoặc có được thông qua việc sử dụng Nền Tảng của Chúng Tôi là an
              toàn. Chúng Tôi sẽ không chịu trách nhiệm về những tổn thất với hệ
              thống máy tính của Bạn hoặc việc mất mát dữ liệu. Chúng Tôi khuyên
              Bạn nên liên hệ với bộ phận Chăm sóc Khách Hàng khi có dấu hiệu
              nghi ngờ hệ thống máy tính, thiết bị truy cập của Bạn bị ảnh
              hưởng, bị nhiễm virus, phần mềm độc hại trong quá trình sử dụng
              Phần mềm Website.
            </p>
            <p className="content-text-term">
              <span style={{ color: "black", fontWeight: "bold" }}> 4</span>.
              Chúng Tôi sẽ chỉ làm việc, giải quyết các khiếu nại liên quan tới
              Dịch Vụ mà Chúng Tôi cung cấp cho Bạn thông qua Phần mềm Website.
            </p>
          </div>
        </div>
        <h2 className="text-item">V. QUYỀN SỞ HỮU TRÍ TUỆ</h2>
        <div style={{ paddingLeft: "12px" }}>
          <p className="content-text-term">
            <span style={{ color: "black", fontWeight: "bold" }}> 1</span>. .
            Chúng Tôi sở hữu tất cả các Quyền Sở Hữu Trí Tuệ trên Phần mềm
            Website này. Tất cả các thông tin và tài liệu, bao gồm nhưng không
            giới hạn ở phần mềm, văn bản, dữ liệu, đồ họa, hình ảnh, âm thanh,
            video, nhãn hiệu, logo, biểu tượng, mã HTML và các mã khác trên Phần
            mềm Website này (“Quyền Sở Hữu Trí Tuệ”) đều bị cấm công bố, sửa
            đổi, sao chép, phân phối hoặc sử dụng nếu không có sự cho phép của
            Chúng Tôi. Không có bất cứ quy định nào trong Điều Khoản và Điều
            Kiện này được hiểu là trao cho Bạn một sự cho phép hoặc bất kỳ quyền
            nào để sử dụng, chiếm hữu, phân phối hoặc sửa đổi bất kỳ Quyền Sở
            Hữu Trí Tuệ nào của Chúng Tôi. Nếu Bạn vi phạm quy định này, Chúng
            Tôi bảo lưu quyền yêu cầu Bạn bồi thường cho toàn bộ thiệt hại hoặc
            tổn thất mà Chúng Tôi phải gánh chịu. Những hành vi vi phạm cũng có
            thể cấu thành tội phạm hình sự theo quy định của pháp luật.
          </p>
          <p className="content-text-term">
            <span style={{ color: "black", fontWeight: "bold" }}> 2 </span>.
            Toàn bộ nội dung trên Phần mềm Website được bảo hộ theo pháp luật về
            quyền sở hữu trí tuệ và quyền liên quan khác. Chúng Tôi và các bên
            cấp phép của Chúng Tôi sở hữu quyền sở hữu trí tuệ và/hoặc các quyền
            liên quan khác đối với các nội dung trên Nền Tảng. Bạn không được
            sửa đổi, sao chép, trưng bày, xuất bản hoặc khai thác toàn bộ hay
            một phần những nội dung này, trừ trường hợp được sự đồng ý bằng văn
            bản của Chúng Tôi.
          </p>
        </div>
        <h2 className="text-item">
          VI. GIẢI QUYẾT TRANH CHẤP, KHIẾU NẠI PHÁT SINH
        </h2>
        <div style={{ paddingLeft: "12px" }}>
          <p>
            <span style={{ color: "black", fontWeight: "bold" }}> 1</span>. Khi
            có bất kỳ khiếu nại, tranh chấp nào xảy ra liên quan đến các dữ liệu
            trên Phần mềm Website, Bạn vui lòng liên hệ, thông báo đến bộ phận
            Chăm sóc Khách hàng của Chúng Tôi trong thời gian sớm nhất.
          </p>
          <p className="content-text-term">
            <span style={{ color: "black", fontWeight: "bold" }}> 2 </span>.
            Chúng Tôi sẽ tiếp nhận thông tin từ phía Bạn và xác thực thông tin.
            Trong vòng 07 ngày kể từ ngày nhận được yêu cầu Phần mềm Website sẽ
            thông báo tới bên có liên quan về những khiếu nại trên.
          </p>
          <p className="content-text-term">
            <span style={{ color: "black", fontWeight: "bold" }}> 3</span>
            . Mọi tranh chấp phát sinh sẽ được ưu tiên giải quyết thông qua thương
            lượng. Trong vòng 30 ngày kể từ ngày nhận được yêu cầu, nếu các Bên
            không thể thương lượng hòa giải, tranh chấp sẽ được giải quyết tại
            Tòa án nhân dân có thẩm quyền.{" "}
          </p>
          <p className="content-text-term">
            <span style={{ color: "black", fontWeight: "bold" }}> 4 </span>.
            Chúng tôi cam kết giải quyết mọi tranh chấp, khiếu nại trên cơ sở
            khách quan, dựa trên thỏa thuận của các bên và quy định pháp luật.
          </p>
        </div>
      </div>
      <FormRegister targetRef={targetRef} />
    </>
  );
};
export default TermsConditions;
