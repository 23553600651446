import { Form, Input, Modal, Select } from "antd";
import RegisterApi from "../../../Api/Register";
import "./registerPage.scss";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import imgSuccess from "../../../asset/images/success.png";
import imgError from "../../../asset/images/error.png";
import { useDispatch } from "react-redux";
import { CloseLoading, openLoading } from "../../../redux/Slice/loadingSlice";
const options = [
  {
    label: ">10 xe",
    value: "1",
  },
  {
    label: "10-20 xe",
    value: "2",
  },
  {
    label: "20-50 xe",
    value: "3",
  },
  {
    label: ">50 xe",
    value: "4",
  },
];
const { Option } = Select;
const Register = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenError, setIsModalOpenError] = useState(false);
  const [successe, setSuccess] = useState("");
  const [error, setError] = useState([]);
  const hiddenModal = () => {
    setIsModalOpen(false);
    setIsModalOpenError(false);
  };
  const onFinishFailed = (errorInfo) => {
    
  };
  const onFinish = async (values) => {
    try {
       
      const selectedOption = options.find(option => option.value === values.contact_company_size);
      const selectedLabel = selectedOption ? selectedOption.label : '';
 
      const valueData = {
        ...values,
        site_id: 1,
        contact_company_size: selectedLabel,
      };
      dispatch(openLoading());
      const response = await RegisterApi.Create(valueData);
         
        if (response.status === 200) {
         
            dispatch(CloseLoading());
            setIsModalOpen(true);
            setSuccess(response.data.message);
            form.resetFields();
          // })
          return response;
        }
        if (response.status === 429) {
          dispatch(CloseLoading());
          setIsModalOpen(true);
          setSuccess(response.data.message);
          
        } else {
          dispatch(CloseLoading());
          setIsModalOpenError(true);
          setError(response.data.message);  
        }
    } catch (error) {   
        dispatch(CloseLoading());
        setIsModalOpenError(true);
        setError("Lỗi đăng kí xin vui lòng thử lại sau!");  
    }
   
  };
  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="formRegister"
      >
        <div className="inputRegister">
          <div className="inputLeft inputLeftMobile">
            <p className="titleInput titleInputMobile">
              Tên công ty(nhà xe)<sup className="subText">*</sup>
            </p>
            <Form.Item
              name="contact_company_name"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tên công ty(nhà xe)!",
                },
              ]}           
            >
              <Input placeholder="Nhập tên công ty(nhà xe)" />
            </Form.Item>
          </div>
          <div className="inputRight">
            <p className="titleInput titleInputMobile">
              Người liên hệ <sup className="subText">*</sup>
            </p>
            <Form.Item
              name="contact_name"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập người liên hệ!",
                },
              ]}
            >
              <Input placeholder="Nhập tên người liên hệ" />
            </Form.Item>
          </div>
        </div>
        <div>
          <p className="titleInput titleInputMobile">
            Địa chỉ <sup className="subText">*</sup>
          </p>
          <Form.Item
            name="contact_address"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập địa chỉ!",
              },
            ]}
          >
            <Input placeholder="Nhập địa chỉ" />
          </Form.Item>
        </div>
        <div className="inputRegister">
          <div className="inputLeft inputLeftMobile">
            <p className="titleInput titleInputMobile">
              Số điện thoại <sup className="subText">*</sup>{" "}
            </p>
            <Form.Item
              name="contact_phone"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập số điện thoại!",
                },
                {
                  pattern: /^[0-9]+$/,
                  message: "Vui lòng chỉ nhập số !",
                },
              ]}
           
            >
              <Input placeholder="Nhập số điện thoại" />
            </Form.Item>
          </div>
          <div className="inputRight">
            <p className="titleInput titleInputMobile">
              Quy mô <sup className="subText">*</sup>
            </p>
            <Form.Item
              name="contact_company_size"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập quy mô!",
                },
              ]}
            >
              <Select
                placeholder="Vui lòng chọn quy mô"
                style={{ width: "100%" }}
              >
                 {options.map(option => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
          </div>
        </div>
        <div>
          <p className="titleInput titleInputMobile">
            Email
          </p>
          <Form.Item
            name="contact_email"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập email!",
              },
              {
                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Vui lòng nhập đúng email !",
              },
            ]}          
          >
            <Input placeholder="Nhập Email" />
          </Form.Item>
        </div>
        <div>
          <p className="titleInput titleInputMobile">
            Lời nhắn
          </p>
          <Form.Item name="contact_message">
            <TextArea row={4} placeholder="Nhập lời nhắn bạn muốn để lại" />
          </Form.Item>
        </div>
        <button
          className="buttonRegister buttonRegisterMobiles"
          type="submit"
        >
          Đăng ký ngay
        </button>
      </Form>
      <div >
        <Modal open={isModalOpen} className="modalNotification modalNotificationMobile">
          <img alt="" className="imageSuccses imageSuccsesMobile" src={imgSuccess} />
          {imgSuccess && <p>{successe} </p>}
          <button onClick={hiddenModal} className="buttonConfirm buttonConfirmMobile">
            Đã Hiểu
          </button>
        </Modal>
      </div>
      <div >
        <Modal open={isModalOpenError} className="modalNotification modalNotificationMobile">
          <img alt="" className="imageSuccses imageSuccsesMobile" src={imgError} />
          {imgError && <p>{error} </p>}
          <button onClick={hiddenModal} className="buttonConfirm buttonConfirmMobile">
            Đã Hiểu
          </button>
        </Modal>
      </div>
    </>
  );
};
export default Register;
