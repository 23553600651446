import AxiosClient from "./api";
const Site = process.env.REACT_APP_SITE_ID;
const GetFags = (config) =>{
    return AxiosClient.get(`faqs/${Site}/lang/1`, config)
}
const GetById = (id, config)=>{
    return AxiosClient.get(`banner/${id}`, config)
}
const Create = (data, config) => {
    return AxiosClient.post("banner/1", data, config);
  };
const Update = (id, data, config) => {
    return AxiosClient.put(`banner/1/${id}`, data, config);
  };
const Delete = (id, config) => {
    return AxiosClient.delete(`banner/1${id}`, config);
  };
const RecruitmentApi = {GetFags,GetById,Create,Update,Delete};

export default RecruitmentApi;