import AxiosClient from "./api";
const GetListJob = (id) =>{
    return AxiosClient.get(`listjobsfilterbycategory/${id}/site/1/lang/1/limit/4/filter/*`)
}
const GetListJobFilter = (id, filter) =>{
  return AxiosClient.get(`listjobsfilterbycategory/${id}/site/1/lang/1/limit/4/filter/${filter}`)
}
const GetListBannerCategory = (id, config )=>{
    return AxiosClient.get(`listjobsbycategory/${id}/site/1/lang/1/limit/4`, config)
}
const GetListInformationJob = ( config) => {
    return AxiosClient.get("getcategorybyid/4", config);
  };
  const CreateRegisterCv = (data, config) => {
    return AxiosClient.post("/applycv", data, config);
  };
const JobApi = {GetListJob,GetListJobFilter,GetListBannerCategory,GetListInformationJob,CreateRegisterCv};

export default JobApi;