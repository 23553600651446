import { useEffect, useState } from "react";
import React from "react";
import "./slideNew.scss";
import "react-slideshow-image/dist/styles.css";
import PostNewsApi from "../../../Api/New";
import { useNavigate } from "react-router";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SlideNew = () => {
  const navigate = useNavigate();
  const [listPostNew, setListPostNew] = useState([]);
  const FetchListPostNew = async (config = {}) => {
    try {
      const response = await PostNewsApi.GetAll(config);
      setListPostNew(response.data);
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    FetchListPostNew();
  }, []);
  const handleAliasActive = () => {
    sessionStorage.setItem("activeMenu", "tin-tuc");
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleClickId = () => {
    scrollToTop();
    sessionStorage.setItem("activeMenuItemId", 3);
    sessionStorage.setItem("activeMenu", "tin-tuc");
  };
  const ListPostNewMobile = listPostNew.slice(0, 3);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
  };
  return (
    <>
    <div className="wapperNews">

    <Slider  
      {...settings}>
      {listPostNew.map((item, index) => (
            <div
              className="containerSlideShow"
              key={index}
              onClick={() => {
                handleAliasActive();
                navigate(`/tin-tuc/chi-tiet/${item.news_id}`);
                scrollToTop();
              }}
            >
              <div className="slideImage">
                <img alt="" src={item.news_hot_image_url} />
              </div>
              <div className="bodyTitileSlide">
                <div className="titileSlide">
                  <p>{item.title}</p>
                </div>
                <div className="newDescription">
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          ))}
      
      </Slider>

    </div>
      {/* mobile */}
    
      <div className="containerSlideNew">
      {ListPostNewMobile.map((item, index) => (
          <div
            className="WapperNewMobile"
            key={index}
            onClick={() => {
              navigate(`/tin-tuc/chi-tiet/${item.news_id}`);
              handleAliasActive();
              scrollToTop();
            }}
          >
            <div className="slideImageMobile">
              <img alt="" src={item.news_hot_image_url} />
            </div>
            <div className="containerContentNewMobile">
              <div className="titileSlideMobile">
                <p>{item.title}</p>
              </div>
              <div className="newDescription">
                <p>{item.description}</p>
              </div>
            </div>
          </div>
      ))}
        </div>
    
      <div className="buttonNewPost">
        <button
          onClick={() => {
            navigate(`/tin-tuc`);
            handleClickId();
          }}
        >
          Xem thêm
        </button>
      </div>
    </>
  );
};

export default SlideNew;
