import AxiosClient from "./api";
const Site = process.env.REACT_APP_SITE_ID;
const GetAll = (config) =>{
    return AxiosClient.get(`partners/${Site}`, config)
}
const GetProductByCategoryId = ( config)=>{
    return AxiosClient.get(`partners/${Site}/lang/1`, config)
}

const PartnerApi = {GetAll,GetProductByCategoryId};

export default PartnerApi