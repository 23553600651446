import { Collapse } from "antd";
import "./questions.scss";
import { useEffect, useState } from "react";
import RecruitmentApi from "../../../Api/Recrument";
const Questions = () => {
  const [listFags, setListFags] = useState([]);
  const fetchListFags = async (config = {}) => {
    try {
      const response = await RecruitmentApi.GetFags(config);
      setListFags(response?.data?.data.data);
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    fetchListFags();
  }, []);
  const { Panel } = Collapse;
  const onChange = (key) => {
  
  };
  return (
    < div > 
      <div
        className="containerQuestion containerQuestionMobile"
      >
        <div
          className="titleQuestion titleQuestionMobile"
        >
          <h1>Câu hỏi thường gặp</h1>
          <p>
            Chúng tôi biết rằng bạn có thể có một số câu hỏi trước, trong hoặc
            sau cuộc phỏng vấn với chúng tôi. Dưới đây là danh sách một số câu
            hỏi mà chúng tôi được hỏi thường xuyên nhất.
          </p>
        </div>
        <div
          className="navbarContent navbarContentMobile"
        >
          <Collapse onChange={onChange}>
            {listFags?.length && listFags?.map((item) => (
              <Panel header={item?.question} key={item?.id}>
                <p>{item?.answer}</p>
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </div>
  );
};
export default Questions;
