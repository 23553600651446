import { createSlice } from "@reduxjs/toolkit";
 const modalSlices = createSlice({
    name:'modal',
    initialState:{
        isOpen: false,
    },
    reducers:{
        openModal:(state)=>{
            state.isOpen = true;
        },
        closeModal:(state)=>{
            state.isOpen = false;
        }
    },
});
export const {openModal, closeModal} = modalSlices.actions;
export default modalSlices.reducer;
