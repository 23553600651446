import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AxiosClient from '../../Api/api'; 
const Site = process.env.REACT_APP_SITE_ID;
export const getListThemse = createAsyncThunk (
  'listThemseBus', async(page)=>{
    try{
       const response = await  AxiosClient.get(`list_themes/${Site}?Page=${page}`);
       const {data ,status} = response
       if(status === 200) {
        setData(data)
        return data
      }
      return response;
    }
    catch(error) {
        
    }
  }
)
const themseBusSlice = createSlice({
  name: 'themseBus',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {
    setData: (state, action)=> {
      state.data = action?.payload?.data;
     
    },
    setError: (state, action)=> {
      state.error = action.error.message;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListThemse.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getListThemse.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getListThemse.rejected, (state, action) => {
        state.loading = false;
      });
  },
  
});

export default themseBusSlice.reducer;
export const {setData} = themseBusSlice.actions
