import AxiosClient from "./api";
const Site = process.env.REACT_APP_SITE_ID;
const GetAll = (page) =>{
    return AxiosClient.get(`list_themes/${Site}?Page=${page}`)
}
const GetById = (id, config)=>{
    return AxiosClient.get(`/list_subpage_themes/${id}`, config)
}
const ThemseApi = {GetAll,GetById};


export default ThemseApi