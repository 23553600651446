import "./home.scss";
import {useEffect, useRef, useState } from "react";
import imgae129 from "../../asset/images/image129.png";
import SlideNew from "../../Component/PageComponent/SlidePage/SlideNew";
import ReviewCustomer from "../../Component/PageComponent/ReviewCustomer/ReviewCustomer";
import BannerApi from "../../Api/Banner";
import GoTop from "../../Component/PageComponent/GoTop/GoTop";
import Product from "../../Component/PageComponent/ProductPage/Product";
import FormRegister from "../../Component/PageComponent/FormRegister/FormRegister";
import ContactChild from "../../Component/PageComponent/Contact/Contact";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
const Home = ({ targetRef, scrollToForm }) => {
  const listInformation = useSelector((state)=>state?.information?.data);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const targetRefs = useRef(null);
  const scrollToTarget = () => {
    scrollToForm();
  };
    const navigate = useNavigate();
  const [listBanner, setListBanner] = useState([]);
  const fetchBanner = async (config = {}) => {
    try {
      const response = await BannerApi.GetAll(config);
      setListBanner(response?.data);
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    fetchBanner();
  }, []);

  return (
    <>
      <Helmet>
        <title>{listInformation[0]?.site_title}</title>
        <meta name="description" content={listInformation[0]?.site_description}/>
        <meta name="keywords" content={listInformation[0]?.site_keywords} />
        <meta property="og:locale" content="" />
        <meta property="og:type" content="" />
        <meta property="og:description" content="" />
        <meta property="og:url" content="" />
        <meta property="og:site_name" content="" />
        <meta property="og:img" content="" />
      </Helmet>

      {listBanner?.length && listBanner?.map((item, index) => (
        <div
          style={{
            backgroundImage: `url("${item?.banner_image_url}")`,
            marginTop: "72px",
          }}
          className="wapperBannerHome "
          key={index}
        >
          <div className="bannerHome ">
            <div className="bannerRight ">
              <h1>{item?.banner_title}</h1>
              <h3
                dangerouslySetInnerHTML={{ __html: item?.banner_descriptiom }}
              ></h3>
              <div className="risgter ">
                <button className="risgterHover" onClick={scrollToTarget}>
                  Đăng kí dùng thử ngay
                </button>
              </div>
            </div>
          
          </div>
        </div>
      ))}
  {/* tablet */}
    { listBanner?.length && listBanner?.map((item, index) => (
            <div
              style={{
                backgroundImage: `url("${item?.banner_image_tablet_url}")`,
                marginTop: "72px",
              }}
              className="wapperBannerHomeTablet"
              key={index}
            >
              <div className="bannerHomeTablet">
                <div className=" bannerRightTablet">
                  <h1>{item?.banner_title}</h1>
                  <h3
                    dangerouslySetInnerHTML={{ __html: item?.banner_descriptiom }}
                  ></h3>
                  <div className="risgterTablet">
                    <button className="risgterHover" onClick={scrollToTarget}>
                      Đăng kí dùng thử ngay
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
      {/* mobile */}
       { listBanner?.length && listBanner?.map((item, index) => (
        <div
          style={{
            backgroundImage: `url("${item?.banner_image_mobile_url}")`,
            marginTop: "72px",
          }}
          className="wapperBannerHomeMobile"
          key={index}
        >
          <div className=" bannerHomeMobile">
            <div className=" bannerRightMobile">
              <h1>{item?.banner_title}</h1>
              <h3
                dangerouslySetInnerHTML={{ __html: item?.banner_descriptiom }}
              ></h3>
              <div className=" risgterMobiles">
                <button className="risgterHover" onClick={scrollToTarget}>
                  Đăng kí dùng thử ngay
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="wapperAdvantage">
        <div className="advantageTitle advantageTitleMobile">
          <h3>Ưu điểm nổi bật</h3>
          <h2>Yếu tố tạo lên sự đột phá</h2>
        </div>
        <Product />
      </div>
      <FormRegister targetRef={targetRef} targetRefs={targetRefs} />
      <div className="introduceNew introduceNewMobile">
        <div className="introduceNewTitle introduceNewTitleMobile">
          <p>Nền tảng quản lý và vận hành nhà xe toàn diện</p>
        </div>
        <h2 className="textIntroduceMobile">
          Được tin dùng bởi 2000+ nhà xe trên cả nước
        </h2>
        <div className="introduceDescription introduceDescriptionMobile">
          <p>
            Hiện tại, với website và ứng dụng được xây dựng và cải tiến mỗi
            ngày, CarLink chính là nền tảng hỗ trợ hoạt động vận hành và quản lý
            cho hơn 200 nhà xe chở khách hàng đầu Việt Nam ở tất cả các khu vực
            trên cả nước và trải dài trên các tuyến đường dài ngắn khác nhau như
            Hà Nội - Sapa; Sài Gòn - Đà Lạt; Hà Tĩnh - Nha Trang;...
          </p>
          <p className="TextBottom TextBottomMobile">
            Tất cả các Ứng dụng và Website cũng như Dashboard có thể sử dụng dễ
            dàng trên trình duyệt, smartphone (iOS, Android), máy tính bảng,
            hoặc cài đặt trên máy tính để bàn. Tất cả đều hỗ trợ đa ngôn ngữ
            (Tiếng Việt, English) và nâng cấp liên tục.
          </p>
        </div>
        <div className="introduceImage introduceImageMobile">
          <img alt="" src={imgae129} />
        </div>
      </div>
      <div className="wapperNew wapperNewMobile">
        <div className="headerNew headerNewMobile">
          <div className="titleNew titleNew">
            <p className="textNew textNewMobile">Tin tức</p>
            <p className="titleNewHot titleNewHotMobile">Tin nóng trong tuần</p>
            <p className="titleUpdate titleUpdateMobile">
              Các tin tức luôn được cập nhật liên tục cho bạn một tầm nhìn...
            </p>
          </div>
          <div className="buttonNew buttonNewMobile">
            <button
              onClick={() => {
                navigate(`/tin-tuc`);
                scrollToTop();
              }}
            >
              Tất cả tin tức
            </button>
          </div>
        </div>
        <SlideNew />
      </div>
      <ReviewCustomer />
      <ContactChild />
      <GoTop />
    </>
  );
};
export default Home;
